<template>
    <div class="table-responsive">
        <b-table
            id="my-table"
            responsive
            :items="lista"
            :fields="campos"
            :per-page="perPage"
            :current-page="currentPage"
            striped
        >
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <template #cell(acoes)="data">
                <slot name="acoes" v-bind:data="data"></slot>
            </template>
        </b-table>

        <hr class="mt-5" />

        <div class="mt-4">
            <div class="d-flex justify-content-between align-items-center">
                <b-pagination
                    first-number
                    last-number
                    v-model="currentPage"
                    :perPage="perPage"
                    aria-controls="my-table"
                    :total-rows="rows"
                ></b-pagination>

                <a
                    :href="download"
                    target="_blank"
                    class="btn btn-outline-dark btn-sm"
                    :class="{ disabled: rows==0 }"
                    ><i class="lni lni-download mr-1"></i>
                   Download</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        lista: Array,
        campos: Array,
        download: String
    },
    data() {
        return {
            perPage: 15,
            currentPage: 1
        };
    },

    methods: {
        data: function(data) {
            return moment(String(data)).format("dd/MM/YYYY HH:mm:ss");
        }
    },
    computed: {
        rows() {
            return this.lista.length;
        }
    }
};
</script>
