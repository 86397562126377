<template>
  <div>
    <slot name="spinner" v-if="condicao">
      <div class="d-flex align-items-center">
        <strong>Carregando...</strong>
        <b-spinner class="ml-auto"></b-spinner>
      </div>
    </slot>

    <slot name="default" v-else> </slot>
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    condicao: Boolean,
  },
};
</script>
