import Vue from 'vue';

export default new Vue({
    methods: {
        emitir(evento, conteudo){
            this.$emit(evento, conteudo);
        },
        escutar(evento, callback){
            this.$on(evento, callback);
        }
    }    
})