<template>
    <div class="formulario">
        <div class="row">
            <!-- formulario -->
            <div class="col-sm-6">
              <slot name="coluna-1"></slot>
            </div>
            <!-- segunda coluna -->
            <div class="col-sm-6">
              <slot name="coluna-2"></slot>
            </div>
        </div>
    </div>   
</template>

<script>
export default {
}
</script>