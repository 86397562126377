<template>
  <div>
   <!-- <b-navbar-toggle target="barralateral" class="d-block d-sm-none"><i class="lni lni-line-double"></i></b-navbar-toggle>
        <b-collapse id="barralateral" is-nav>
            <b-navbar-nav class="ml-auto d-flex align-items-right">
                <BarraLateral />
            </b-navbar-nav>
        </b-collapse>-->
    <BarraLateral />

    <section class="conteudo">
      <div class="container-fluid mt--7">
        <Navbar></Navbar>
        <div class="container-fluid pt-0">
            <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BarraLateral from "./components/layout/BarraLateral";
import Navbar from "./components/layout/Navbar";

export default {
  components: {
    BarraLateral,
    Navbar,
  },

};
</script>