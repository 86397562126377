<template>
  <Carregando :condicao="dados == null || listaColecoes == null || listaColPerAntiga == null || id_usuario == null">    
      <!-- formulario -->
      <h3>Editando "{{pergunta}}"</h3>
      <br />
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="pergunta">Pergunta: <span class="text-danger">*</span></label>
          <b-form-input
            id="pergunta"
            type="text"
            class="form-control"
            :placeholder="pergunta"
            v-model="dados.question"
            :state="(dados.question == null)? null: dados.question != ''"
          ></b-form-input>  
        </div>
        <label for="colecoes">Coleções que contêm a pergunta: <span class="text-danger">*</span></label>
          <b-form-checkbox name="colecoes" v-model="dados.collections" v-for="colecao in listaColecoes" :key="colecao.id" :value="colecao.id">{{colecao.name}}</b-form-checkbox>
      </div>
      <div class="col-sm-6"> 
          <b-form-radio v-model="dados.correct" name="checked" value="A" ><label>Alternativa A: <span class="text-danger">*</span></label></b-form-radio>
          <b-form-input type="text" class="form-control" v-model="dados.option1"
            :state="(dados.option1 == null)? null: dados.option1 != ''"
          ></b-form-input>            
          <b-form-radio v-model="dados.correct" name="checked" value="B"><label>Alternativa B: <span class="text-danger">*</span></label></b-form-radio>
          <b-form-input type="text" class="form-control" v-model="dados.option2"
            :state="(dados.option2 == null)? null: dados.option2 != ''"
          ></b-form-input>         
          <b-form-radio v-model="dados.correct" name="checked" value="C"><label>Alternativa C: <span class="text-danger">*</span></label></b-form-radio>
          <b-form-input type="text" class="form-control" v-model="dados.option3"
            :state="(dados.option3 == null)? null: dados.option3 != ''"
          ></b-form-input> 
          <b-form-radio v-model="dados.correct" name="checked" value="D"><label>Alternativa D: <span class="text-danger">*</span></label></b-form-radio>
          <b-form-input type="text" class="form-control" v-model="dados.option4"
            :state="(dados.option4 == null)? null: dados.option4 != ''"
          ></b-form-input> 
          <b-form-radio v-model="dados.correct" name="checked" value="E"><label>Alternativa E: <span class="text-danger">*</span></label></b-form-radio>
          <b-form-input type="text" class="form-control" v-model="dados.option5"
            :state="(dados.option5 == null)? null: dados.option5 != ''"
          ></b-form-input> 
        </div>
      </div>
      <div class="float-right">
        <router-link
          tag="button"
          class="btn btn-light"
          :to="{ name: 'perguntas'}"
          exact
          >Voltar</router-link> 
        <button class="btn btn-success m-2" type="button" @click="cadastrar">
          Enviar
        </button>
      </div>
  </Carregando>
</template>
<script>
//import axios from "axios";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
  },

  data() {
    return {
      dados: null,
      pergunta: "",
      listaColecoes: null,
      listaColPerAntiga: null,
      id_usuario: null,
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    backEnd.pergunta.obterPergunta(this.$route.params.id, VueCookie.get('authToken'), resp => {
      this.dados = resp.data;
      this.pergunta = resp.data.question;
    })
    backEnd.colecao.listarPorTipo("questions", VueCookie.get('authToken'), (resposta) => {
      this.listaColecoes = resposta.data;
    });
    backEnd.colecaoPergunta.listarPorPergunta(this.$route.params.id, VueCookie.get('authToken'), lista => {
      this.listaColPerAntiga = lista.data;
      this.dados.collections = [];
      this.listaColPerAntiga.forEach(item => {
        this.dados.collections.push(item.collection_id)
      })
    });
  },

  methods: {
    cadastrar() {
      console.log(this.dados.collections)
      if(this.dados.question == '' ||
        this.dados.collections.length == 0 ||
        this.dados.option1 == '' ||
        this.dados.option2 == '' ||
        this.dados.option3 == '' ||
        this.dados.option4 == '' ||
        this.dados.option5 == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      console.log(this.dados);
      backEnd.pergunta.editarPergunta(this.$route.params.id, this.dados, VueCookie.get('authToken'),(pergunta)=>{
        console.log(pergunta);
        if(this.dados.collections !== []){
          this.listaColPerAntiga.forEach((item) => {
            console.log(" collection-question excluído: ",item)
            backEnd.colecaoPergunta.deletar(item.id, VueCookie.get('authToken'), resp => {
              console.log(resp)});
          });
        }
        this.dados.collections.forEach(id_colecao => {
          backEnd.colecaoPergunta.cadastrar({collection_id: id_colecao, question_id: pergunta.data.id, user_id: this.id_usuario}, VueCookie.get('authToken'), resp => {
            console.log("novo collection-question: ",resp.data);
          });
        });
        this.$router.push({name: 'perguntas'});
      });
    },
  },

  computed: {
  },
};
</script>
