<template>
  <div>
    <h3>Editando {{dadosVersao.name}} do {{dadosJogo.name}}</h3>
    <br>
    
    <Carregando :condicao="listaElems ==null || listaElemsDesativados ==null || listaValoresElems ==null || dadosVersao ==null">
          <template slot="spinner">
            <div class="col-md-8">
               <b-skeleton-table
              :rows="5"
              :columns="2"
              :table-props="{ bordered: false, striped: false }"
            ></b-skeleton-table>
            
            </div>
           
          </template>
    <div >
      <b-container v-if="passo1" class="text-center">
          <h5>Arraste para a caixa da esquerda os elementos que deseja utilizar nessa versão e para a caixa da direita os que não serão usados.</h5>
          <b-row align-h="center" align-v="start">
          <b-col align-self="center">
            <div class="p2 alert alert-secondary">
              <h4>Elementos ativados</h4>
              <draggable class="list-group" v-model="listaElems" group="elementos" @start="drag=true" @end="drag=false">
              <transition-group>
                <div class="list-group-item" v-for="elemento in listaElems" :key="elemento.id">{{elemento.name}}</div>
              </transition-group>        
            </draggable>
            </div>
            
          </b-col>
          <b-col align-self="center">
            
            <div class="p2 alert alert-secondary">
              <h4>Elementos desativados</h4>
            <draggable class="list-group" v-model="listaElemsDesativados" group="elementos" @start="drag=true" @end="drag=false">
              <transition-group>
                <div class="list-group-item" v-for="elemento in listaElemsDesativados" :key="elemento.id">{{elemento.name}}</div>
              </transition-group>        
            </draggable>
            </div>
          </b-col>          
        </b-row>
        <router-link
            tag="button"
            class="btn btn-light"
            :to="{ name: 'jogo-versoes', params: { id: this.dadosVersao.game_id, new: 'none' } }"
            exact
            >Voltar
          </router-link> 
        <button class="btn btn-success" @click="proxPasso()">Próximo</button>
      </b-container>
      <b-container v-else>
        <FormEditarVersao
        :gameID="dadosVersao.game_id"
        :versionID="Version_ID"
        :versionName="dadosVersao.name"
        :elementsList="listaElems"
        :offElemList="listaElemsDesativados"
        :elemValuesList="listaValoresElems"
        :selectOptions="listaOpcoesSelect"
        :collectionList="listaColecoes">
        </FormEditarVersao>
        <button class="btn btn-light" @click="passo1 = !passo1">Voltar</button>
      </b-container>       
    </div>
    </Carregando>
  </div>  
</template>

<script>
import FormEditarVersao from "@/views/Versoes/FormEditarVersao";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';
//import axios from "axios";
import draggable from 'vuedraggable';
import Carregando from "@/components/layout/Carregando";

export default {
  components: {
    FormEditarVersao,
    draggable,
    Carregando,
  },

  data () {
    return {
      Version_ID: this.$route.params.id,
      dadosVersao: [],
      dadosJogo: [],
      listaElems: null,
      listaElemsDesativados: [],
      listaValoresElems: null,
      listaOpcoesSelect: null,
      listaColecoes: null,
      passo1: true,
      id_usuario: null,
    }
  },

  methods: {
    proxPasso(){
      if(this.listaElemsDesativados != null){
        this.listaElemsDesativados.forEach(offElem => {
          let offValue = this.listaValoresElems.find(value => value.game_element_id == offElem.id);
          if(offValue !== undefined && offValue.elemNovo !== 1){
            offValue.activated = 'false';           
            backEnd.parametro.deletar(offValue.id, VueCookie.get('authToken'), () => {
            });
          }
        });
        this.listaElems.forEach(onElem => {
          let onValue = this.listaValoresElems.find(value => value.game_element_id == onElem.id);
          if(onValue !== undefined && onValue.elemNovo !== 1){
            onValue.activated = 'true';  
            onValue.user_id = this.id_usuario;         
            backEnd.parametro.editarParametro(onValue.id, onValue, VueCookie.get('authToken'), () => {
            });
          }
        });
      }
      this.passo1= false;
    },
  },

  created () {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    //Obtendo dados da versão a ser editada
    backEnd.versoes.obterVersao(this.Version_ID, VueCookie.get('authToken'),(resposta) => {
      this.dadosVersao=resposta.data;
      console.log(this.dadosVersao)
      backEnd.jogos.obterJogo(this.dadosVersao.game_id, VueCookie.get('authToken'), (resp) => {this.dadosJogo = resp.data;});
      //Obtendo elementos do jogo
      backEnd.elementos.listarPorJogo(this.dadosVersao.game_id, VueCookie.get('authToken'), (resp) => {
        this.listaElems = resp.data;
         //Obtendo parâmetros da versão a ser editada    
        backEnd.parametro.listarPorVersao(this.Version_ID, VueCookie.get('authToken'), (resposta) => {
          this.listaValoresElems = resposta.data;
          this.listaElems.forEach(elem => {
            let valor = this.listaValoresElems.find(valor => {return valor.game_element_id == elem.id});
            if(valor != undefined){
              if(valor.activated == true || valor.activated == "true"){
                elem.activated = true;
              }else{
                elem.activated = false;
              }
            }else{
              elem.activated = false;
            }
          });
          this.listaElemsDesativados = this.listaElems.filter(elem => elem.activated == false);
          this.listaElems = this.listaElems.filter(elem => elem.activated == true);
        });
    
        this.listaElems.forEach(elemento => {
          if(elemento.type == "select"){
            backEnd.valores_elemento.listarPorElemento(elemento.id, VueCookie.get('authToken'), opcoes => {
              if(this.listaOpcoesSelect == null){
                this.listaOpcoesSelect = opcoes.data;
              }else{
                opcoes.data.forEach(opcao => {
                  this.listaOpcoesSelect.push(opcao);
                });
              }
            });
          }
        });
      });
    });
    backEnd.colecao.listar( VueCookie.get('authToken'), resp => this.listaColecoes = resp.data);
  },
};
</script>

<style>
.buttons {
 align-items: right;
}
</style>
