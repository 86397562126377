<template>
  <Carregando :condicao="listaImagens == [] || titulo == ''">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>
    <Secao
    :titulo="titulo"
    icone="lni lni-library"
  >
  <h4>Descrição: {{dadosColecao.description}}</h4>
   <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>     
    <div class="float-right">
      <router-link
        tag="button"
        class="btn btn-success"
        :to="{ name: 'cadastrar-imagem' }"
        exact>Nova Imagem
      </router-link>
    </div>   

      <Tabela :lista="listaImagens" :campos="campos">
        <template v-slot:acoes="slotProps">
          <div>
            <b-button 
              :id="'imagem'+slotProps.data.item.id"
              v-b-modal="'modal-imagem-'+ slotProps.data.item.id" class="btn btn-sm btn-success mx-1">Ver imagem</b-button>
            <b-tooltip :target="'imagem'+slotProps.data.item.id" triggers="hover" placement="right">
                Visualizar imagem {{slotProps.data.item.name}} 
              </b-tooltip>
              <b-modal :id="'modal-imagem-'+ slotProps.data.item.id" :title="'Visualizando '+slotProps.data.item.name" ok-only ok-variant="ligth" ok-title="Fechar">
                <div  ><v-img id="imagem-antiga" 
                max-height="500"
                max-width="500" 
                contain
                :src="obterImagem(slotProps.data.item.path)"/></div>
            </b-modal> 
            <router-link
              :id="'editar'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning"
              :to="{ name: 'editar-imagem', params: { id: slotProps.data.item.id } }"
              exact
            ><i class="lni lni-pencil-alt"></i>
            </router-link>
            <b-tooltip :target="'editar'+slotProps.data.item.id" triggers="hover" placement="right">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>
            <b-button 
              :id="'excluir'+slotProps.data.item.id"
              v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger mx-1"><i class="lni lni-trash"></i></b-button>
            <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarImagem(slotProps.data.item.id)">
              <p class="my-4">Tem certeza que deseja excluir essa imagem? <br/>
                Essa operação é irreversível!</p>
            </b-modal>    
            <b-tooltip :target="'excluir'+slotProps.data.item.id" triggers="hover" placement="right">
                Excluir {{slotProps.data.item.name}} 
              </b-tooltip>           
          </div>
        </template>
      </Tabela>
      <router-link
        tag="button"
        class="btn btn-light"
        :to="{ name: 'imagens'}"
        exact
        >Voltar
      </router-link>      
    
  </Secao></Carregando>
</template>

<script>
import Secao from "@/components/layout/Secao";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Secao,
    Carregando,
    Tabela,
  }, data() {
    return {
      listaImagens: [],
      titulo: '',
      dadosColecao: "",

      campos: [
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    }
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecaoImagem.listarPorColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      let listaCollectionImage = resp.data;
      listaCollectionImage.forEach(collectionImage => {
        backEnd.imagem.obterImagem(collectionImage.image_id, VueCookie.get('authToken'), (imagem) => {
          if(imagem.data !== []){
            this.listaImagens.push(imagem.data);
          }
        });
      });
    });
    backEnd.colecao.obterColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      this.titulo = "Imagens da "+resp.data.name;
      this.dadosColecao = resp.data;  
    });
  },

  methods: {
    obterImagem(caminho){
      return `${process.env.VUE_APP_API_URL}`+caminho;
    },
    deletarImagem(id_imagem){
      backEnd.colecaoImagem.listarPorImagem(id_imagem, VueCookie.get('authToken'), resposta => {
          let lista = [];
          if(resposta.data !== undefined){
            resposta.data.forEach((item) => {
              if(item.collection_id !== Number(this.$route.params.id_colecao)){
                lista.push(item);
              }
            });
            if(lista.length !== 0){
              this.mensagemExclusao = `Essa imagem não pode ser excluída pois está em outra(s) ${lista.length} coleção(ões).`;
              this.variantExclusao = "warning";
              this.alertExclusao = true;
            }else{
              backEnd.imagem.deletar(id_imagem, VueCookie.get('authToken'), resp => {
                if(resposta.data.length !== 0){
                  resposta.data.forEach(item => {
                    backEnd.colecaoImagem.deletar(item.id, VueCookie.get('authToken'), data => {
                      console.log(data);
                    });                  
                  });
                }        
                this.listaImagens = this.listaImagens.filter(img => img.id !== id_imagem);        
                console.log(resp);
                this.mensagemExclusao = `Imagem excluída com sucesso!`;
                this.variantExclusao = "success";
                this.alertExclusao = true;
              });
          
            }
          }
          
      });
    }    
  }
};
</script>
