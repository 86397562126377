<template>
  <CardNoHeader>
    <template slot="corpo">
      <!-- formulario -->
      <h3>Nova Coleção de {{tipoColecao}}</h3>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <br />
      <div class="col-sm-6">
        <div class="form-group">
          <label>Nome: <span class="text-danger">*</span></label>
          <b-form-input
            type="text"
            class="form-control"
            placeholder="Nome da coleção"
            v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
          ></b-form-input>   
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <label>Descrição: <span class="text-danger">*</span></label>
          <b-form-textarea
            class="form-control"
            placeholder="Descreva a coleção"
            v-model="dados.description"
          :state="(dados.description == null)? null: dados.description != ''"
          ></b-form-textarea>
        </div>
      </div>

      <div class="float-right">
         <router-link
          tag="button"
          class="btn btn-light "
          :to="{ name: this.$route.params.type}"
          exact
          >Voltar</router-link> 
        <button class="btn btn-success" type="button" @click="cadastrar">
          Enviar
        </button>
      </div>
    </template>
  </CardNoHeader>
</template>

<script>
//import axios from "axios";
import CardNoHeader from "@/components/layout/CardNoHeader";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    CardNoHeader,
  },

  data() {
    return {
      dados: {
        name: null,
        description: null,
        type: "",
        user_id: "",
      },
      id_usuario: null,
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },
  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
  },

  methods: {
    cadastrar() {
      if(this.dados.name == '' || this.dados.description == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      let destino = "";
      if(this.tipoColecao == 'Perguntas'){
        this.dados.type = 'questions';
        destino = "perguntas";
      }else{
         if(this.tipoColecao == 'Imagens'){
          this.dados.type = 'images';
          destino = "imagens";
         }else{
           this.dados.type = 'sounds';
          destino = "sons";
         }
        
      }
      
      console.log(this.dados);
      backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
        this.dados.user_id = id.data;
        backEnd.colecao.cadastrar(this.dados, VueCookie.get('authToken'),()=> {
          this.$router.push({name: destino}); 
        });
      });
      
    },
  },
  computed: {
    tipoColecao (){
      if(this.$route.params.type == "perguntas"){
        return "Perguntas";
      }else{
        if(this.$route.params.type == "imagens"){
          return "Imagens";
        }else{
          return "Sons"
        }
      }
    },    
  },
};
</script>
