<template>
  <div class="card p-3">
    <div class="card-body">
      <slot name="corpo"></slot>
    </div>
    <div class="card-footer text-muted">
      <slot name="rodape"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icone: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "./../../../src/sass/temas.scss";

@each $theme in $themes {
    $elemento: map-get($theme, "elemento");
    #{$elemento} {

.card{
    -webkit-box-shadow: 60px 60px 60px 60px rgba(82, 63, 105, 0.05);
    box-shadow: 60px 60px 30px 60px rgba(82, 63, 105, 0.05);
    border-radius: 1.2rem;
    border: none;
    background:  map-get($theme, "cardBackground");
    .card-footer{
        background: transparent!important;
        border-top: map-get($theme, "cardBorda");
        border-bottom-left-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
}

    }
}

</style>