<template>
  <Carregando :condicao="listaColecoes == null || id_usuario == null">    
      <!-- formulario -->
      <h3>Novo Som</h3>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <br />
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Nome do Som: <span class="text-danger">*</span></label>
            <b-form-input
              type="text"
              class="form-control"
              v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
            ></b-form-input>          
          </div> 
          <label>Coleções que contêm o som: <span class="text-danger">*</span></label>
          <b-form-checkbox name="colecoes" v-model="dados.collections" v-for="colecao in listaColecoes" :key="colecao.id" :value="colecao.id">{{colecao.name}}</b-form-checkbox>
        </div>
        <div class="col-sm-6">
          <label for="">Arquivo do Som: <span class="text-danger">*</span></label>
          <form enctype="multipart/form-data" >
            <div class="dropbox">
              <!--<input type="file" name="sound" class="input-file" @change="fileChange"> -->
              <b-form-file
                v-model="dados.file"
                placeholder="Selecione um som..."
                drop-placeholder="Solte um som aqui..."
                accept=".wav"
                :state="(dados.file == null)? null: true"
              ></b-form-file> 
            </div>
          </form>
        </div>
      </div>
      <div class="float-right">
        <router-link
          tag="button"
          class="btn btn-light"
          :to="{ name: 'sons'}"
          exact
          >Voltar</router-link>
        <b-button v-b-modal="'modal-enviar'" class="btn btn-success">Enviar</b-button>
        <b-modal :id="'modal-enviar'" title="Atenção!" cancel-variant="ligth" ok-variant="outline-success" ok-title="Eu concordo" cancel-title="Cancelar" @ok="cadastrar()">
          <p class="my-4">Ao cadastrar esse som você garante que tem direitos autorais sobre ele. <br/>
          </p>
        </b-modal> 
      </div>
  </Carregando>
</template>
<script>
//import axios from "axios";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
  },

  data() {
    return {
      dados: {
        name: null,
        file: null,
        collections: [],
      },      
      listaColecoes: null,
      id_usuario: null,
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    backEnd.colecao.listarPorTipo("sounds", VueCookie.get('authToken'),(resposta) => {
      this.listaColecoes = resposta.data;
    });
  },

  methods: {
    /*fileChange(e){
      console.log(e.target.files[0]);
      this.dados.file = e.target.files[0];
    },*/
    cadastrar() {
      if(this.dados.name == '' || this.dados.file == null || this.dados.collections == []){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      console.log(this.dados);
      let data = new FormData();
      data.append('name', this.dados.name);
      data.append('user_id', this.id_usuario);
      data.append('file', this.dados.file);
      backEnd.som.cadastrar(data, VueCookie.get('authToken'), som => {
        console.log(som);
        this.dados.collections.forEach(id_colecao => {
          backEnd.colecaoSom.cadastrar({collection_id: id_colecao, sound_id: som.data.id, user_id: this.id_usuario}, VueCookie.get('authToken'), resposta => {
            console.log(resposta);
            backEnd.colecaoSom.listar(VueCookie.get('authToken'), lista => console.log("Lista collection-som por som:", lista.data))  
          });
        });
        this.$router.push({name: 'sons'});
      });
      
    },
  },

  computed: {
  },
};
</script>
