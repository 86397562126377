<template>
  <Carregando :condicao="listaSons == [] || titulo == ''">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>
    <Secao
    :titulo="titulo"
    icone="lni lni-music"
  >
  <h4>Descrição: {{dadosColecao.description}}</h4>
   <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>     
    <div class="float-right">
      <router-link
        tag="button"
        class="btn btn-success"
        :to="{ name: 'cadastrar-som' }"
        exact>Novo Som
      </router-link>
    </div>   

      <Tabela :lista="listaSons" :campos="campos">
        <template v-slot:acoes="slotProps">
          <div>
            <b-button 
            :id="'som'+slotProps.data.item.id"
            v-b-modal="'modal-som-'+ slotProps.data.item.id" class="btn btn-sm btn-success">Ouvir som</b-button>
              <b-modal :id="'modal-som-'+ slotProps.data.item.id" :title="'Reproduzindo '+slotProps.data.item.name" ok-only ok-variant="ligth" ok-title="Fechar">
                <div align="center">                
                <audio controls autoplay>
                  <source :src="obterSom(slotProps.data.item.path)" type="audio/ogg">
                  <source :src="obterSom(slotProps.data.item.path)" type="audio/mpeg">
                  <source :src="obterSom(slotProps.data.item.path)" type="audio/wav">
                  Seu navegador não suporta a reprodução de aúdios.
                </audio>
                </div>
            </b-modal> 
            <b-tooltip :target="'som'+slotProps.data.item.id" triggers="hover" placement="right">
                Ouvir {{slotProps.data.item.name}} 
              </b-tooltip>
            <router-link
              :id="'editar'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning"
              :to="{ name: 'editar-som', params: { id: slotProps.data.item.id } }"
              exact
            ><i class="lni lni-pencil-alt"></i>
            </router-link>
            <b-tooltip :target="'editar'+slotProps.data.item.id" triggers="hover" placement="right">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>

            <b-button 
            :id="'excluir'+slotProps.data.item.id"
            v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger"><i class="lni lni-trash"></i></b-button>
            <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarSom(slotProps.data.item.id)">
              <p class="my-4">Tem certeza que deseja excluir esse som? <br/>
                Essa operação é irreversível!</p>
            </b-modal> 
            <b-tooltip :target="'excluir'+slotProps.data.item.id" triggers="hover" placement="right">
                Excluir {{slotProps.data.item.name}} 
              </b-tooltip>
              
          </div>
        </template>
      </Tabela>
      <router-link
        tag="button"
        class="btn btn-light"
        :to="{ name: 'sons'}"
        exact
        >Voltar
      </router-link>      
    
  </Secao></Carregando>
</template>

<script>
import Secao from "@/components/layout/Secao";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Secao,
    Carregando,
    Tabela,
  }, data() {
    return {
      listaSons: [],
      titulo: '',
      dadosColecao: "",

      campos: [
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    }
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecaoSom.listarPorColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      let listaCollectionSound = resp.data;
      listaCollectionSound.forEach(collectionSound => {
        backEnd.som.obterSom(collectionSound.sound_id, VueCookie.get('authToken'), (som) => {
          if(som.data !== []){
            this.listaSons.push(som.data);
          }
        });
      });
    });
    backEnd.colecao.obterColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      this.titulo = "Sons da "+resp.data.name;
      this.dadosColecao = resp.data;  
    });
  },

  methods: {
    obterSom(caminho){
      return `${process.env.VUE_APP_API_URL}`+caminho;
    },
    deletarSom(id_som){
      backEnd.colecaoSom.listarPorSom(id_som, VueCookie.get('authToken'), resposta => {
          let lista = [];
          if(resposta.data !== undefined){
            resposta.data.forEach((item) => {
              if(item.collection_id !== Number(this.$route.params.id_colecao)){
                lista.push(item);
              }
            });
            if(lista.length !== 0){
              this.mensagemExclusao = `Esse som não pode ser excluído pois está em outra(s) ${lista.length} coleção(ões).`;
              this.variantExclusao = "warning";
              this.alertExclusao = true;
            }else{
              backEnd.som.deletar(id_som, VueCookie.get('authToken'), resp => {
                if(resposta.data.length !== 0){
                  resposta.data.forEach(item => {
                    backEnd.colecaoSom.deletar(item.id, VueCookie.get('authToken'), data => {
                      console.log(data);
                    });                  
                  });
                }        
                this.listaSons = this.listaSons.filter(img => img.id !== id_som);        
                console.log(resp);
                this.mensagemExclusao = `Som excluído com sucesso!`;
                this.variantExclusao = "success";
                this.alertExclusao = true;
              });
          
            }
          }
          
      });
    }    
  }
};
</script>
