@@ -0,0 +1,90 @@
<template>
  <div class="row">
    
     <div class="login row flex-column-reverse flex-lg-row m-0">
    <div class="bg-light col-xl-8">

        <h1 style="font-weight: 100; font-size: 2.2rem"><span style="font-weight: 700;">Oba!</span> Você vai criar a sua conta.</h1>
    </div>
    <div class="conteudo d-flex align-items-center justify-content-center col-xl-4">
        <div class="w-100">
            <div class="col-12 text-center mb-5">
                <h3 class="titulo">Olá!</h3>
                <p class="text-center">Digite abaixo as suas credenciais</p>
            </div>
            

            <div class="form-group row mt-5">
                <label for="nome" class="col-md-12 col-form-label sr-only">Nome:  <span class="text-danger">*</span></label>

                <div class="col-md-12">
                    <input id="nome" placeholder="Seu nome" v-model="form.name" type="text" class="form-control " value="">
                </div>
                <label for="email" class="col-md-12 col-form-label sr-only">Endereço de e-mail:  <span class="text-danger">*</span></label>

                <div class="col-md-12">
                    <input id="email" placeholder="Endereço de e-mail" v-model="form.email" type="email" class="form-control " name="email" value="" required autocomplete="email" autofocus>
                </div>
            </div>

            <div class="form-group row">
                <label for="password" class="col-md-12 col-form-label sr-only">Senha:  <span class="text-danger">*</span></label>

                <div class="col-md-12">
                    <input id="password" placeholder="Senha" v-model="form.password" type="password" class="form-control " name="password" required autocomplete="current-password">
                </div>
            </div>

            <div class="form-group row mb-0">
                <div class="col-md-12">
                    <button @click="cadastrar" class="btn btn-outline-dark btn-block">
                        Criar minha conta :)
                    </button> 
                    <div class="d-flex justify-content-center align-items-center">
                      <router-link
                        tag="a"
                        style="font-weight:bold"
                        :to="{ name: 'login'}"
                        >Voltar para tela de login</router-link>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
  },

  data() {
    return {
      form: {
        email:'',
        name: '',
        password: '',
      },
      alertExclusao: true,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },
  methods: {
    cadastrar(){
      backEnd.usuario.cadastrar(this.form, resp => {
        VueCookie.set('authToken', resp.data.api_token, 1);
        this.$router.push({
          name: "home",
        });
      });
    },
  },
};
</script>