import axios from "axios";
//import VueCookie from 'vue-cookie';

let exports = {};
exports.jogos = {};
exports.usuario = {};
exports.versoes = {};
exports.elementos = {};
exports.parametro = {};
exports.valores_elemento = {};
exports.colecao = {};
exports.pergunta = {};
exports.som = {};
exports.imagem = {};
exports.colecaoPergunta = {};
exports.colecaoImagem = {};
exports.colecaoSom = {};

exports.get = (url, token, callback) => {
  axios.get(url, {
      headers: {
        'token': token,
      }
    })
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {      
      if(erros.response.status == 401){
        console.log(erros.response.status);
        //VueCookie.delete('authToken');
      }else{
        callback(erros);
      }      
    });
}

exports.post = (url, dados, token, callback) => {
  axios
    .post(url,dados, {
      headers: {
        'token': token,
      }
    })
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      if(erros.response.status == 401){
        console.log(erros.response.status);
       // VueCookie.delete('authToken');
      }else{
        callback(erros);
      }   
    });
}

exports.patch = (url, dados, token, callback) => {
  axios
    .patch(url,dados, {
      headers: {
        'token': token,
      }
    })
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      if(erros.response.status == 401){
        console.log(erros.response.status);
        //VueCookie.delete('authToken');
      }else{
        callback(erros);
      }   
    });
}

exports.delete = (url, token, callback) => {
  axios
    .delete(url, {
      headers: {
        'token': token,
      }
    })
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      if(erros.response.status == 401){
        console.log(erros.response.status);
        //VueCookie.delete('authToken');
      }else{
        callback(erros);
      }   
    });
}

exports.usuario.cadastrar = (dados, callback) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/users/create`, dados)
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      callback(erros);
    });
};

exports.usuario.obterUsuario= (id, callback) => {
  axios
  .get(`${process.env.VUE_APP_API_URL}/users/view/${id}`)
  .then((resposta) => {
    callback(resposta);
  })
  .catch((erros) => {
    callback(erros);
  });
};

exports.usuario.obterId= (token, callback) => {
  axios
  .get(`${process.env.VUE_APP_API_URL}/users/getID/${token}`)
  .then((resposta) => {
    callback(resposta);
  })
  .catch((erros) => {
    callback(erros);
  });
};

exports.usuario.verificar= (dados, callback) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/users/verify`, dados)
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      callback(erros);
    });
  
};

exports.usuario.deletar= (id, callback) => {
  axios
    .delete(`${process.env.VUE_APP_API_URL}/users/delete/${id}`)
    .then((resposta) => {
      callback(resposta);
    })
    .catch((erros) => {
      if(erros.response.status == 401){
        console.log(erros.response.status);
        //VueCookie.delete('authToken');
      }else{
        callback(erros);
      }   
    });  
};

exports.jogos.obterLista = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/games/list`, token, callback);
};

exports.jogos.obterJogo = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/games/view/${id}`, token, callback);
};

exports.jogos.editarJogo = (id, dados, token, callback) => {
  exports.patch(`${process.env.VUE_APP_API_URL}/games/update/${id}`, dados, token, callback);
};

exports.jogos.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/games/create`, dados, token, callback);
};

exports.jogos.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/games/delete/${id}`, token, callback);
};



exports.versoes.obterLista = (token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/versions/list`, token, callback);
};

exports.versoes.obterVersao = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/versions/view/${id}`, token, callback);
};

exports.versoes.editarVersao = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/versions/update/${id}`, dados, token, callback);
};

exports.versoes.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/versions/delete/${id}`, token, callback);
};

exports.elementos.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/elements/create`,dados, token, callback);
}

exports.elementos.obterLista = (token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/elements/list`, token, callback);
}

exports.elementos.obterElemento = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/elements/view/${id}`, token, callback);
};

exports.elementos.editarElemento = (id, dados, token, callback) => {
  exports.patch(`${process.env.VUE_APP_API_URL}/elements/update/${id}`, dados, token, callback);
};

exports.elementos.listarPorJogo = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/elements/listByGame/${id}`, token, callback);
};

exports.elementos.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/elements/delete/${id}`, token, callback);
};



exports.parametro.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/parameter/create`, dados, token, callback);
};

exports.parametro.obterLista = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/parameter/list`, token, callback);
};

exports.parametro.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/parameter/delete/${id}`, token, callback);
};

exports.parametro.editarParametro = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/parameter/update/${id}`, dados, token, callback);
};

exports.parametro.listarPorVersao = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/parameter/listByVersion/${id}`, token, callback);
};


exports.valores_elemento.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/values/create`, dados, token, callback);
};

exports.valores_elemento.listarPorElemento = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/values/listByGameElement/${id}`, token, callback);
};

exports.valores_elemento.obterLista = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/values/list`, token, callback);
};

exports.valores_elemento.editarOpcao = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/values/update/${id}`, dados, token, callback);
};

exports.valores_elemento.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/values/delete/${id}`, token, callback);
};


exports.colecao.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/collections/create`, dados, token, callback);
};

exports.colecao.listarPorTipo = (tipo, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collections/listByType/${tipo}`, token, callback);
};

exports.colecao.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collections/list`, token, callback);
};

exports.colecao.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/collections/delete/${id}`, token, callback);
};

exports.colecao.editarColecao = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/collections/update/${id}`, dados, token, callback);
};

exports.colecao.obterColecao = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collections/view/${id}`, token, callback);
};

exports.pergunta.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/question/create`, dados, token, callback);
};

exports.pergunta.obterPergunta = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/question/view/${id}`, token, callback);
};

exports.pergunta.editarPergunta = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/question/update/${id}`, dados, token, callback);
};

exports.pergunta.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/question/delete/${id}`, token, callback);
};

exports.colecaoPergunta.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-question/list`, token, callback);
};

exports.colecaoPergunta.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/collection-question/create`, dados, token, callback);
};

exports.colecaoPergunta.listarPorColecao = (collection_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-question/listByCollection/${collection_id}`, token, callback);
};

exports.colecaoPergunta.listarPorPergunta = (question_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-question/listByQuestion/${question_id}`, token, callback);
};

exports.colecaoPergunta.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/collection-question/delete/${id}`, token, callback);
};

exports.imagem.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/image/create`, dados, token, callback);
};

exports.imagem.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/image/delete/${id}`, token, callback);
};

exports.imagem.deletarArquivo = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/image/deleteFile/${id}`, token, callback);
};

exports.imagem.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/image/list`, token, callback);
};

exports.imagem.obterImagem = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/image/view/${id}`, token, callback);
};

exports.imagem.obterLink = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/image/url/${id}`, token, callback);
};

exports.imagem.editarImagem = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/image/update/${id}`, dados,  token, callback);
};

exports.imagem.editarImagemArquivo = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/image/updateWithFile/${id}`, dados, token, callback);
};

exports.colecaoImagem.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-image/list`, token, callback);
};

exports.colecaoImagem.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/collection-image/create`, dados, token, callback);
};

exports.colecaoImagem.listarPorColecao = (collection_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-image/listByCollection/${collection_id}`, token, callback);
};

exports.colecaoImagem.listarPorImagem = (image_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-image/listByImage/${image_id}`, token, callback);
};

exports.colecaoImagem.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/collection-image/delete/${id}`, token, callback);
};


exports.colecaoSom.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-sound/list`, token, callback);
};

exports.colecaoSom.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/collection-sound/create`, dados, token, callback);
};

exports.colecaoSom.listarPorColecao = (collection_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-sound/listByCollection/${collection_id}`, token, callback);
};

exports.colecaoSom.listarPorSom = (sound_id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/collection-sound/listBySound/${sound_id}`, token, callback);
};

exports.colecaoSom.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/collection-sound/delete/${id}`, token, callback);
};


exports.som.cadastrar = (dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/sound/create`, dados, token, callback);
};

exports.som.deletar = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/sound/delete/${id}`, token, callback);
};

exports.som.deletarArquivo = (id, token, callback) => {
  exports.delete(`${process.env.VUE_APP_API_URL}/sound/deleteFile/${id}`, token, callback);
};

exports.som.listar = ( token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/sound/list`, token, callback);
};

exports.som.obterSom = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/sound/view/${id}`, token, callback);
};

exports.som.obterLink = (id, token, callback) => {
  exports.get(`${process.env.VUE_APP_API_URL}/sound/url/${id}`, token, callback);
};

exports.som.editarSom = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/sound/update/${id}`, dados,  token, callback);
};

exports.som.editarSomArquivo = (id, dados, token, callback) => {
  exports.post(`${process.env.VUE_APP_API_URL}/sound/updateWithFile/${id}`, dados, token, callback);
};
export default exports;
