<template>
  <Carregando :condicao="listaPerguntas == [] || titulo == ''">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>
    <Secao
    :titulo="titulo"
    icone="lni lni-library"
  >
  <h4>Descrição: {{dadosColecao.description}}</h4>
  <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
    >{{mensagemExclusao}}</b-alert>     
    <div class="float-right"><router-link
      tag="button"
      class="btn btn-success"
      :to="{ name: 'cadastrar-pergunta'}"
      exact
      >Nova pergunta</router-link>
    </div>  

      <Tabela :lista="listaPerguntas" :campos="campos">
        <template v-slot:acoes="slotProps">

          <div>
            <router-link
            :id="'editar'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning"
              :to="{ name: 'editar-pergunta', params: { id: slotProps.data.item.id } }"
              exact
              ><i class="lni lni-pencil-alt"></i></router-link>
              <b-tooltip :target="'editar'+slotProps.data.item.id" triggers="hover" placement="right">
                Editar 
              </b-tooltip>
              <b-button
              :id="'excluir'+slotProps.data.item.id"
              v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger"><i class="lni lni-trash"></i></b-button>

              <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarPergunta(slotProps.data.item.id)">
                <p class="my-4">Tem certeza que deseja excluir essa pergunta? <br/>
                  Essa operação é irreversível!</p>
              </b-modal> 
              <b-tooltip :target="'excluir'+slotProps.data.item.id" triggers="hover" placement="right">
                Excluir
              </b-tooltip>
          </div>
        </template>
      </Tabela>
      <router-link
        tag="button"
        class="btn btn-light"
        :to="{ name: 'perguntas'}"
        exact
      >Voltar</router-link>

  </Secao>
  </Carregando>
</template>

<script>
import Secao from "@/components/layout/Secao";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Secao,
    Carregando,
    Tabela,
  }, data() {
    return {
      listaPerguntas: [],
      titulo:  '',
      dadosColecao: '',

      campos: [
          {
            key: 'question',
            label: 'Pergunta',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    }
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecaoPergunta.listarPorColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      let listaCollectionQuestion = resp.data;
      listaCollectionQuestion.forEach(collectionQuestion => {
        backEnd.pergunta.obterPergunta(collectionQuestion.question_id, VueCookie.get('authToken'), (pergunta) => {
          if(pergunta.data !== []){
            this.listaPerguntas.push(pergunta.data);
          }
        });
      });
    });
    backEnd.colecao.obterColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
      this.titulo = "Perguntas da "+resp.data.name
      this.dadosColecao = resp.data;
    });
  },

  methods: {
    deletarPergunta(id_pergunta){
      backEnd.colecaoPergunta.listarPorPergunta(id_pergunta, VueCookie.get('authToken'), resposta => {
        let lista = resposta.data.filter(item => item.collection_id !== this.$route.params.id_colecao);
        if(lista.length !== 0){
          console.log(lista);
          this.mensagemExclusao = `Essa pergunta não pode ser excluída pois está em outra(s) ${lista.length} coleção(ões).`;
          this.variantExclusao = "warning";
          this.alertExclusao = true;
        }else{
          backEnd.pergunta.deletar(id_pergunta, VueCookie.get('authToken'), resp => {
            console.log(lista);
            resposta.data.forEach(item => backEnd.colecaoPergunta.deletar(item.id, VueCookie.get('authToken'), data => {
              console.log(data);
              this.listaPerguntas = [];
              backEnd.colecaoPergunta.listarPorColecao(this.$route.params.id_colecao, VueCookie.get('authToken'), resp => {
                let listaCollectionQuestion = resp.data;
                listaCollectionQuestion.forEach(collectionQuestion => {
                  backEnd.pergunta.obterPergunta(collectionQuestion.question_id, VueCookie.get('authToken'), (pergunta) => {
                    if(pergunta.data !== []){
                      this.listaPerguntas.push(pergunta.data);
                    }
                  });
                });
              });
            }));
            console.log(resp);
            this.mensagemExclusao = `Pergunta excluída com sucesso!`;
            this.variantExclusao = "success";
            this.alertExclusao = true;
          });

        }
      })
    }
  }

};
</script>
