<template>
  <div>
    <h3>Editando {{elemento.name}}</h3>
    <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <Formulario>
        <template #coluna-1>
            <div class="form-group">
              <label>Nome: <span class="text-danger">*</span></label>
              <b-form-input
                type="text"
                class="form-control"  
                :placeholder="nomeElemento"
                v-model="elemEditado.name"
                :state="(elemEditado.name == null)? null: elemEditado.name != ''"
              ></b-form-input>

              <label>Descrição: <span class="text-danger">*</span></label>
              <b-form-input
                type="text"
                class="form-control"  
                :placeholder="descricaoElemento"
                v-model="elemEditado.description"
              :state="(elemEditado.description == null)? null: elemEditado.description != ''"
              ></b-form-input>
            </div>

        </template>

        <template #coluna-2>
            <div class="form-group">
              <label>Tipo: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                placeholder="Tipo"
                v-model="elemEditado.type"
                id="selectGameplay"
              >
                <option
                  value=""
                  disabled
                >Selecione uma opção</option>
                <option
                  v-for="(gameplayOpcao, indice) in gameplayOpcoes"
                  :key="indice"
                  :value="gameplayOpcao.toLowerCase()"
                >
                  {{ gameplayOpcao }}
                </option>
              </select>
            </div>
            <div
              class="form-group"
              v-if="elemEditado.type == 'select'"
            >
              <label for="tags-remove-on-delete">Opções:</label>
              <b-form-tags
                input-id="tags-remove-on-delete"
                :input-attrs="{
                  'aria-describedby': 'tags-remove-on-delete-help',
                }"
                v-model="dadosSelect"
                size="lg"
                separator=" "
                placeholder="Insira as opções do select"
                font-size="sm"
                remove-on-delete
              ></b-form-tags>
            </div>
            <div>
            <b-button
              class="btn btn-success"
              type="button"
              @click="enviar"
            >Enviar</b-button>
            <router-link
              tag="button"
              class="btn btn-light"
              :to="{ name: 'jogo-elementos', params: { id: elemento.game_id, new: 'none' } }"
              exact
              >Voltar</router-link> 
          </div>
        </template>
      </Formulario> 
  </div>
</template>

<script>
//import axios from "axios";
import Formulario from "@/components/layout/Formulario";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Formulario,
  },

  data() {
    return {
      gameplayOpcoes: ["String","Select","Boolean","Integer"],
      nomeElemento: "",
      descricaoElemento: "",
      elemento: [],
      elemEditado:{
          name: "",
          type: "",
          description: "",
      },
      id_usuario: null,
      dadosSelect: null,
      gameElementID: this.$route.params.id,
            
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
      
    };
  },

  methods: {
    async enviar() {
      if(this.elemEditado.name == '' || this.elemEditado.description == '' || this.elemEditado.type == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      this.elemEditado.user_id = this.id_usuario;
      if(this.elemEditado.name == ""){
        this.elemEditado.name = this.elemento.name;
      }
      await backEnd.elementos.editarElemento(this.gameElementID,this.elemEditado, VueCookie.get('authToken'), () => {
        if(this.elemEditado.type == "select"){
          backEnd.valores_elemento.listarPorElemento(this.gameElementID, VueCookie.get('authToken'), listOpcoes => {
            if(listOpcoes.data.length !== 0){
              listOpcoes.data.forEach((opcao, index) => {
                backEnd.valores_elemento.deletar(opcao.id, VueCookie.get('authToken'), () => {
                  if(index == listOpcoes.data.length -1){
                    this.dadosSelect.forEach(opcao => {
                      let dado = {name: opcao, games_elements_id: this.gameElementID, value: opcao, user_id: this.id_usuario };
                      backEnd.valores_elemento.cadastrar(dado, VueCookie.get('authToken'), () =>{
                      });
                    });
                  }
                  });
              });
            }else{
              this.dadosSelect.forEach(opcao => {
                let dado = {name: opcao, games_elements_id: this.gameElementID, value: opcao, user_id: this.id_usuario };
                backEnd.valores_elemento.cadastrar(dado, VueCookie.get('authToken'), () =>{
                });
              });
            }
            

          });
          
        }
        
        this.$router.push({
          name: "jogo-elementos",
          params: { id: this.elemento.game_id, new: 'element_edit' },
        });
      });
    },
  },

  created () {
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.elementos.obterElemento(this.$route.params.id, VueCookie.get('authToken'), (resposta) => {
        this.elemento = resposta.data;
        this.nomeElemento = this.elemEditado.name = this.elemento.name;
        this.descricaoElemento = this.elemEditado.description = this.elemento.description;
        this.elemEditado.type = this.elemento.type;
      });
    backEnd.valores_elemento.listarPorElemento(this.$route.params.id, VueCookie.get('authToken'), resp => {
      resp.data.forEach(opcao =>{
        this.dadosSelect.push(opcao.name);
      });
    });
  },
};
</script>
