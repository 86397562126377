<template>
  <CardNoHeader>
    <template slot="corpo">
      <div id="app">
        <div class="row">
          <h3>Nova versão</h3>
          <br>
          <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
            @dismissed="alertExclusao=false;"
          >{{mensagemExclusao}}</b-alert>  
          <!-- formulario -->

          <div class="col-sm-6">

            <form>
              <div class="form-group">
                <label>Nome:  <span class="text-danger">*</span></label>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="Nome da versão"
                  v-model="dados.name"
                :state="(dados.name == null)? null: dados.name != ''"
            ></b-form-input>
              </div>
            </form>
          </div>

          <div class="col-sm-6">
            <form>
              <div class="form-group">
                <label>Game ID:  <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="dados.game_id"
                  class="form-control"
                  :state="(dados.game_id == null)? null: true"
                >
                  <option
                    v-for="jogo in listaJogos"
                    :key="jogo.id"
                    :value="jogo.id"
                  >
                    {{jogo.name}}
                  </option>
                </b-form-select>
              </div>
            </form>

          </div>
        </div>
        <div id="buttons" align="right">
          <button class="btn btn-secondary" @click="dados.name= null; dados.game_id= null;">Limpar</button>
          <button
            class="btn btn-success m-1"
            type="button"
            @click="enviar"
          >Enviar</button>
        </div>
      </div>
    </template>
  </CardNoHeader>
</template>

<script>
import axios from "axios";
import CardNoHeader from "@/components/layout/CardNoHeader";
import EventBus from "@/utils/eventBus"
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    CardNoHeader,
  },

  data () {
    return {
      listaJogos: null,
      dados: {
        name: null,
        game_id: null,
      id_usuario: null,
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
      }
    }
  },

  methods: {
    enviar () {
      if(this.dados.name == '' || this.dados.game_id == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      this.dados.user_id= this.id_usuario;
      axios
        .post(`${process.env.VUE_APP_API_URL}/versions/create`,this.dados,{
          headers: {
            'token': VueCookie.get('authToken'),
          }
        })
        .then((response) => {
          this.$router.push({
          name: "versao-editar",
          params: { id: response.data.id},
          });
          EventBus.emitir("gameIDVersao",{ gameId: response.data.game_id,versionId: response.data.id });
        })
        .catch((err) => {
          console.log(err);
          alert('Falha ao criar versão')
        });
    },
  },
  created () {
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.jogos.obterLista( VueCookie.get('authToken'), (resposta) => {
      this.listaJogos=resposta.data;
    });
  }
};
</script>

<style>
.buttons {
 align-items: right;
}
</style>
