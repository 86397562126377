<template>

  <form>
    <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <h5>Preencha os valores de cadas elemento escolhido nessa versão.</h5>
      <div class="row">
        <!-- primeira coluna -->
        <div class="col-sm-6">
          <label>Nome: <span class="text-danger">*</span></label>
          <b-form-input
            type="text"
            class="form-control"
            :placeholder="versionName"                  
            v-model="versionNameLocal"
            :state="(versionNameLocal == null)? null: versionNameLocal != ''"
            ></b-form-input>
        </div>
        <!-- segunda coluna -->
        <div class="col-sm-6" v-if="elemValuesLocal !== null && elementsList !== null">    
          <div v-if="elemValuesLocal.filter(item => item.game_element_id == elementsList[0].id)[0]">
            <h4>Valores dos Elementos:</h4>
            <b-form-group v-for="(elemento) in elementsList" 
              :key="'elemName-'+elemento.id">
              <!-- Toggle Switch 
              <b-form-checkbox v-model.number="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].activated" name="check-button" size="lg" switch>
                <label 
              :for="'elemento-'+elemento.id">
              {{elemento.name}}
              <i class="lni lni-question-circle" v-b-tooltip.hover :title="elemento.description"></i>
              </label>
              </b-form-checkbox>--> 
              <div> <!-- v-if="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].activated == true || elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].activated == 'true'" -->
                <h5>{{elemento.name}}: <span class="text-danger">*</span> <i class="lni lni-question-circle" v-b-tooltip.hover :title="elemento.description"></i></h5>
                <b-form-input type="text" 
                v-if="elemento.type == 'string'"
                :key="'elemValue-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                :id="'elemento-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                v-model="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value"
                :state="(elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value == null)? null: elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value != ''"
                ></b-form-input>
                
                <b-form-select
                v-if="selectOptions !== null && elemento.type == 'select'"
                :key="'elemValue-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                :id="'elemento-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                v-model="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value"
                :state="(elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value == null)? null: true">                
                  <option v-for="opcao in selectOptions.filter(opcao => opcao.games_elements_id == elemento.id)"
                  :key="'opcaoSelect-'+opcao.id" 
                  :id="'opcaoSelect-'+opcao.id"                 
                  >{{opcao.name}}</option>
                </b-form-select>

                <b-form-input type="number"
                v-if="elemento.type == 'integer'" 
                :key="'elemValue-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                :id="'elemento-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                v-model="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value"
                :state="(elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value == null)? null: elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value != ''"
                ></b-form-input>

                <b-form-select
                v-if="elemento.type == 'boolean'" 
                :key="'elemValue-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                :id="'elemento-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                v-model="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value"
                :state="(elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value == null)? null: true">
                <option value="0">Falso</option>
                <option value="1">Verdadeiro</option>
                <option value="null">Nulo</option>
                </b-form-select>
                <b-form-select
                v-if="collectionList !== null && elemento.type == 'collection'" 
                :key="'elemValue-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                :id="'elemento-'+elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].game_element_id" 
                v-model="elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value"
                :state="(elemValuesLocal.filter((item) => item.game_element_id == elemento.id)[0].value == null)? null: true">                
                  <option v-for="collection in collectionList"
                  :key="'opcaoColecao-'+collection.id" 
                  :id="'opcaoColecao-'+collection.id"    
                  :value="collection.id"             
                  >{{collection.name}}
                  {{(collection.type == "images")?'(Imagens)':((collection.type == "questions")?'(Perguntas)':'(Sons)')}}
                  </option>
                  <option 
                  :key="'opcaoColecao-Nula'" 
                  :id="'opcaoColecao-Nula'"         
                  value=""        
                  >Nulo
                  </option>
                </b-form-select>                
              </div>

            </b-form-group>
          </div>
        </div>
        <div id="buttons" align="right">
          <button
            class="btn btn-success"
            type="button"
            @click="enviar"
          >Enviar</button>
        </div>  
      </div>
    </form>    
</template>

<script>
//import axios from "axios";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
  },
  props: {
    gameID: Number,
    versionID: String,
    versionName: String,
    elementsList: Array,
    elemValuesList: Array,
    selectOptions: Array,
    offElemList: Array,
    collectionList: Array,
  },

  data(){
    return {
      versionNameLocal: null,
      newElemValues: {},
      id_usario: null,
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    }
  },

  methods: {
    enviar(){
      /*if(this.elemValuesLocal.filter(item => item.value == '' || item.value == null ).length !== 0){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }*/
      //Atualizando nome da versão
      if(this.versionNameLocal == "" || this.versionNameLocal == null){
        this.versionNameLocal = this.versionName;
      }
      let dadosVersao= {
          name: this.versionNameLocal,
          game_id: this.gameID,
          user_id: this.id_usuario,
      }
      backEnd.versoes.editarVersao(this.versionID, dadosVersao, VueCookie.get('authToken'), () => {
      });

      //Atualizando valores dos elementos
      this.elemValuesLocal.forEach((elemento) =>
      {
        if(elemento.elemNovo == 1){
          delete elemento.elemNovo;
          elemento.user_id = this.id_usuario;
          let conjElem = [elemento];
          elemento.activated = "true";
          backEnd.parametro.cadastrar(conjElem,  VueCookie.get('authToken'),(resp) => console.log("Novo elemValue cadastrado: ", resp.data));
        }else{
          elemento.user_id = this.id_usuario;
          backEnd.parametro.editarParametro(elemento.id,elemento, VueCookie.get('authToken'),  (resp)=>{console.log("Elem editado com sucesso!", resp.data)});
        }
       });
      this.$router.push({
          name: "jogo-versoes",
          params: { id: this.gameID, new: 'version_edit' }
      });
      
    }
  },

  created(){
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    this.versionNameLocal = this.versionName;
  },

  computed:{
    elemValuesLocal (){
      let listaValues = this.elemValuesList;
      if(listaValues !== null && this.elementsList !== null){
        this.elementsList.forEach(elemento => {
          if(listaValues.find(elemValue => {return elemValue.game_element_id == elemento.id}) == null){
            listaValues.push({
              game_element_id: elemento.id,
              value: null,
              version_id: this.versionID,
              activated: false,
              elemNovo: 1,
            });
          }
        });
        return listaValues;
      }else{
        return null;
      }      
    },
  }
}
</script>

<style lang="scss">
@import "./../../../src/sass/temas.scss";
</style>