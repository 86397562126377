<template>
  <!-- Adicionar elementos ao jogo -->
  <div class="row">
    <div>
      <h1>Versões do {{dadosJogo.name}}</h1>
      <b-alert fade v-if="$route.params.new == 'version'" show variant="success" dismissible @dismissed="$route.params.new = 'none'">Nova versão criada com sucesso!</b-alert>
      <b-alert fade v-if="$route.params.new == 'version_edit'" show variant="success" dismissible @dismissed="$route.params.new = 'none'">Versão editada com sucesso!</b-alert>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
      <div class="float-right"><router-link
        tag="button"
        class="btn btn-success m-2"
        :to="{ name: 'stepperversoes'}"
        exact
        >Nova Versão
      </router-link></div>
      <Carregando :condicao="!listaVersoesFiltrada">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>

      <Tabela :lista="listaVersoesFiltrada" :campos="campos">
        <template v-slot:acoes="slotProps">
            <b-link 
            :id="'jogar'+slotProps.data.item.id"
            :href="gerarLinkJogo(slotProps.data.item.id)" target="_blank" class="btn btn-sm btn-success mx-1">Jogar</b-link>
            <b-tooltip :target="'jogar'+slotProps.data.item.id" triggers="hover" placement="left">
                Abrir {{slotProps.data.item.name}} em nova aba
              </b-tooltip>
            <router-link
              :id="'edit'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning"
              :to="{ name: 'versao-editar', params: { id: slotProps.data.item.id } }"
              exact
              ><i class="lni lni-pencil-alt"></i></router-link>
              <b-tooltip :target="'edit'+slotProps.data.item.id" triggers="hover" placement="left">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>
            <!-- Modal de exclusão -->
              <b-button
                :id="'delete'+slotProps.data.item.id"
                v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger mx-1"><i class="lni lni-trash"></i></b-button>

              <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarVersao(slotProps.data.item.id)">
                <p class="my-4">Tem certeza que deseja excluir {{slotProps.data.item.name}}? <br/>
                  Essa operação é irreversível!</p>
              </b-modal>
              <b-tooltip :target="'delete'+slotProps.data.item.id" triggers="hover" placement="left">
                Excluir {{slotProps.data.item.name}} 
              </b-tooltip>
          
        </template>
      </Tabela>
      <router-link
        tag="button"
        class="btn btn-light"
        :to="{ name: 'jogos'}"
        exact
        >Voltar
      </router-link> 
    </Carregando>
    </div>
  </div>  
</template>

<script>
import backEnd from "@/utils/backEnd.js";
//import EventBus from "@/utils/eventBus";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Tabela,
    Carregando,
  },
  data () {
    return {
      //dadosJogo do jogo
      dadosJogo: {
        name: "",
        description: "",
      },

      //Variáveis da tabela de versoes
      listaVersoesFiltrada: null,
      campos: [
          
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    };
      
  },

  methods: {
    gerarLinkJogo(id){
      return this.dadosJogo.url+`${process.env.VUE_APP_API_URL}`+"/versions/viewParams/"+id;
    },
    deletarVersao(id_versao){
      backEnd.parametro.listarPorVersao(id_versao, VueCookie.get('authToken'), listaParametros => {
        if(listaParametros.data.length == 0){
          //não há parâmetros cadastrados nessa versão
          backEnd.versoes.deletar(id_versao, VueCookie.get('authToken'), () => {
            backEnd.jogos.obterJogo(this.Game_ID, VueCookie.get('authToken'), (resposta) => {
              this.dadosJogo = resposta.data;
              this.listaVersoesFiltrada = resposta.data.versions;
            });
            this.mensagemExclusao ="Versão excluída com sucesso!";
            this.variantExclusao = "success";
            this.alertExclusao = true;
          });
        }else{
          listaParametros.data.forEach((parametro, index) => {
            backEnd.parametro.deletar(parametro.id, VueCookie.get('authToken'), () => {
              if(index == listaParametros.data.length -1){//excluiu o último parâmetro
                backEnd.versoes.deletar(id_versao, VueCookie.get('authToken'), () => {
                  backEnd.jogos.obterJogo(this.Game_ID, VueCookie.get('authToken'), (resposta) => {
                    this.dadosJogo = resposta.data;
                    this.listaVersoesFiltrada = resposta.data.versions;
                  });
                  this.mensagemExclusao ="Versão excluída com sucesso!";
                  this.variantExclusao = "success";
                  this.alertExclusao = true;
                });
              }
            });
          });
        }        
      });
    }
  },

  created () {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.jogos.obterJogo(this.Game_ID, VueCookie.get('authToken'), (resposta) => {
      this.dadosJogo = resposta.data;
      this.listaVersoesFiltrada = resposta.data.versions;
    });
  },

  computed: {
    Game_ID () {
      return this.$route.params.id;
    }
  },
};
</script>
