<template>
  <div>
    <h1>Jogos Cadastrados:</h1>
    <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
      @dismissed="alertExclusao=false;"
    >{{mensagemExclusao}}</b-alert> 
    
    <h5>Utilize o botão adicionar para cadastrar um novo jogo ou use os botões em cada linha da tabela para modificar os jogos já cadastrados. </h5>
    <div class="float-right"><router-link
      tag="button"
      class="btn btn-success m-2"
      :to="{ name: 'jogos-cadastrar' }"
      exact
      >Adicionar</router-link
    ></div>

    <Carregando :condicao="!listaDeJogos">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>
      <Tabela :lista="listaDeJogos" :campos="campos">
        <template v-slot:acoes="slotProps">
              <router-link 
              :id="'elem'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-success mx-1"
              :to="{ name: 'jogo-elementos', params: { id: slotProps.data.item.id, new: 'none' } }"
              exact
              >Ver elementos</router-link>
              <b-tooltip :target="'elem'+slotProps.data.item.id" triggers="hover" placement="right">
                Ver lista dos elementos do {{slotProps.data.item.name}} 
              </b-tooltip>
              <router-link
              :id="'versao'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-success"
              :to="{ name: 'jogo-versoes', params: { id: slotProps.data.item.id, new: 'none' } }"
              exact
              >Ver versões</router-link>
              <b-tooltip :target="'versao'+slotProps.data.item.id" triggers="hover" placement="right">
                Ver lista das versões do {{slotProps.data.item.name}} 
              </b-tooltip>
              <router-link
              :id="'edit'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning mx-1"
              :to="{ name: 'jogo-editar', params: { id: slotProps.data.item.id } }"
              exact
              ><i class="lni lni-pencil-alt"></i>
              </router-link>     
              <b-tooltip :target="'edit'+slotProps.data.item.id" triggers="hover" placement="right">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>         
              <b-button 
              :id="'delete'+slotProps.data.item.id"
              v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger"><i class="lni lni-trash"></i></b-button>
              <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarJogo(slotProps.data.item.id)">
                <p class="my-4">Tem certeza que deseja excluir o {{slotProps.data.item.name}}? <br/>
                  Essa operação é irreversível!</p>
              </b-modal>
              <b-tooltip :target="'delete'+slotProps.data.item.id" triggers="hover" placement="right">
                Excluir {{slotProps.data.item.name}} 
              </b-tooltip>
          
        </template>
      </Tabela>
    </Carregando>
  </div>
</template>

<script>
//import axios from "axios";
//import EventBus from "@/utils/eventBus"
import backEnd from "@/utils/backEnd.js";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
    Tabela,
  },

  data() {
    return {
      listaDeJogos: null,
      campos: [
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'description',
            label: 'Descrição',
            sortable: true
          },

          {
            key: 'slug',
            label: 'Slug',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.jogos.obterLista( VueCookie.get('authToken'), (resposta) => {
      this.listaDeJogos = resposta.data;
    });
  },

  methods: {
    jogoDeletavel(id_jogo){
      backEnd.jogos.obterJogo(id_jogo, VueCookie.get('authToken'), (jogo) => {
        console.log("Dados jogo: ", jogo.data);
        if(jogo.data.totalVersoes !== 0){
          console.log("Esse jogo não pode ser excluído pois possui versões cadastradas.");
          return false;
        }else{            
          //Jogo não possui versões cadastradas
          backEnd.elementos.listarPorJogo(id_jogo, VueCookie.get('authToken'), (elementos) => {
            if(elementos.data.length !== 0){
              console.log("Esse jogo não pode ser excluído pois possui elementos cadastrados.");
              return false;
            }else{
              //Jogo não possui elementos cadastrados/
              console.log("Jogo deletável.");
              return true;
            }
          });
        }
      }); 
    },

    deletarJogo(id_jogo) {
       backEnd.jogos.obterJogo(id_jogo, VueCookie.get('authToken'), (jogo) => {
        if(jogo.data.totalVersoes !== 0){
          this.mensagemExclusao ="Esse jogo não pode ser excluído pois possui versões cadastradas.";
          this.variantExclusao = "danger";
          this.alertExclusao = true;       
        }else{            
          //Jogo não possui versões cadastradas
          backEnd.elementos.listarPorJogo(id_jogo, VueCookie.get('authToken'), (elementos) => {
            if(elementos.data.length !== 0){
              this.mensagemExclusao ="Esse jogo não pode ser excluído pois possui elementos cadastrados.";
              this.variantExclusao = "danger";
              this.alertExclusao = true;             
            }else{
              //Jogo não possui elementos cadastrados/
              backEnd.jogos.deletar(id_jogo, VueCookie.get('authToken'), (resp) => {
                console.log(resp);
                backEnd.jogos.obterLista( VueCookie.get('authToken'), (resposta) => {
                  this.listaDeJogos = resposta.data;
                });
                this.mensagemExclusao ="Jogo excluído com sucesso!";
                this.variantExclusao = "success";
                this.alertExclusao = true;
              });
            }
          });
        }
      });       
    },
  },
}
</script>
