<template>
  <Secao
    titulo="Sons"
    icone="lni lni-music"
  >
  <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
  <div class="float-right"><router-link
    tag="button"
    class="btn btn-success m-2"
    :to="{ name: 'cadastrar-colecao', params: { type: 'sons' } }"
    exact>Nova Coleção
  </router-link>
   </div>
   <Carregando :condicao="!listaColecoes">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>

      <Tabela :lista="listaColecoes" :campos="campos">
        <template v-slot:acoes="slotProps">

          <router-link
          :id="'sons'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-success m-1"
              :to="{ name: 'listar-sons', params: { id_colecao: slotProps.data.item.id } }"
              exact
              >Ver sons</router-link
            >
            <b-tooltip :target="'sons'+slotProps.data.item.id" triggers="hover" placement="right">
                Listar sons da {{slotProps.data.item.name}} 
              </b-tooltip>

          <div>
            <router-link
            :id="'editar'+slotProps.data.item.id"
              tag="button"
              class="btn btn-sm btn-warning mx-1"
              :to="{ name: 'editar-colecao', params: { id: slotProps.data.item.id, type: 'sounds' } }"
              exact
              ><i class="lni lni-pencil-alt"></i></router-link>
              <b-tooltip :target="'editar'+slotProps.data.item.id" triggers="hover" placement="right">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>
              <b-button
              :id="'excluir'+slotProps.data.item.id"
              v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger"><i class="lni lni-trash"></i></b-button>

              <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarColecao(slotProps.data.item.id,slotProps.data.item.name)">
                <p class="my-4">Tem certeza que deseja excluir a {{slotProps.data.item.name}}? <br/>
                  Essa operação é irreversível!</p>
              </b-modal> 
              <b-tooltip :target="'excluir'+slotProps.data.item.id" triggers="hover" placement="right">
                Excluir {{slotProps.data.item.name}} 
              </b-tooltip>
          </div>                      
        </template>
      </Tabela>
    </Carregando>
  </Secao>
</template>

<script>
import Secao from "@/components/layout/Secao";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import backEnd from "@/utils/backEnd.js";
//import axios from "axios";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Secao,
    Carregando,
    Tabela,
  },

  data() {
    return {
      listaColecoes: null,

      campos: [
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'description',
            label: 'Descrição',
            sortable: true
          },

          {
            key: 'slug',
            label: 'Slug',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",
    }
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecao.listarPorTipo("sounds", VueCookie.get('authToken'), (resposta) => {
      this.listaColecoes = resposta.data;
    });
  },

  methods: {
    deletarColecao (id_colecao, nome_colecao){
      backEnd.parametro.obterLista( VueCookie.get('authToken'), lista =>{
        console.log(lista.data)
        console.log(lista.data.find(item => item.value == nome_colecao+" (Sons)"))
        if(lista.data.find(item => item.value == nome_colecao+" (Sons)") !== undefined){
          this.mensagemExclusao = `Essa coleção não pode ser excluída pois está sendo usada em versão(ões) de um jogo.`;
          this.variantExclusao = "warning";
          this.alertExclusao = true;
          return false;
        }else{
          backEnd.colecaoSom.listarPorColecao(id_colecao, VueCookie.get('authToken'), lista => {
            if(lista.data.length != 0){
              this.mensagemExclusao = `Essa coleção não pode ser excluída pois possui ${lista.data.length} som(ns).`;
              this.variantExclusao = "warning";
              this.alertExclusao = true;
            }else{
              backEnd.colecao.deletar(id_colecao, VueCookie.get('authToken'), resp=> {
                backEnd.colecaoSom.listarPorColecao( VueCookie.get('authToken'), resposta => {
                  resposta.data.forEach(item => {
                    backEnd.colecaoSom.deletar(item.id, VueCookie.get('authToken'), resultado => console.log(resultado));
                  });
                });
                console.log(resp);
                this.mensagemExclusao = `Coleção excluída com sucesso!`;
                this.variantExclusao = "success";
                this.alertExclusao = true;
                backEnd.colecao.listarPorTipo("sounds", VueCookie.get('authToken'), (resposta) => {
                  this.listaColecoes = resposta.data;
                });
              });
            }
          });
        }
      });
      
    }
  }

};
</script>
