<template>
  <CardNoHeader>
    <template slot="corpo">
      <FormulateForm
        @submit="submeter"
        v-model="values"
        :schema="schema"
      />
    </template>
  </CardNoHeader>
</template>

<script>
import CardNoHeader from "@/components/layout/CardNoHeader";
import axios from "axios";
import EventBus from "@/utils/eventBus"
import VueCookie from 'vue-cookie';

export default {

  components: {
    CardNoHeader,
  },

  data () {
    return {
      form: [],
      myObj: [],
      idJogo: null,
      idVersao: null,
      elementos: [],
      values: {},
      schema: [],
    }
  },
  methods: {

    submeter () {
      let dados=[];
      let lista=Object.entries(this.values);
      lista.forEach((item) => {
        dados.push({
          version_id: this.idVersao,
          game_element_id: item[0],
          value: item[1]
        })
      });
      dados.forEach((dado)=> {
        let conjDado = [dado];
        axios
        .post(`${process.env.VUE_APP_API_URL}/parameter/create`,conjDado)
        .then((response) => {
          console.log('Post /parameter',response);
        })
        .catch((err) => {
          console.log(err);
          alert('Falha ao submeter elementos')
        });
      })
      this.$router.push({
          name: "jogo-versoes",
          params: { id:  this.idJogo, new: 'version'}
          });
      //alert('Enviou com sucesso');
      
    },
    obterElem (idJogo) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/elements/list`)
        .then((response) => {
          for(var it=0;it<Object.keys(response.data).length;it++) {
            if(response.data[it].game_id==idJogo) this.elementos.push(response.data[it])
          }
          this.montarForm()
          console.log("Response: ",response)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNames: function(n) {
      return this.elementos[n].name
    },

    obterId: function(n) {
      return this.elementos[n].id
    },

    inserirElemSchema (n) {
      //Select:
      if(this.elementos[n].type=='select') {
        this.schema.push(
          {
            "label": this.getNames(n),
            "name": this.obterId(n).toString(),
            "placeholder": "Informe o(a) "+this.getNames(n).toLowerCase()
          })

      } else if(this.elementos[n].type=='boolean') {
        this.schema.push(
          {
            "label": this.getNames(n),
            "name": this.obterId(n).toString(),
            "type": "checkbox",
          })

      } else if(this.elementos[n].type=='integer') {
        this.schema.push(
          {
            "label": this.getNames(n),
            "name": this.obterId(n).toString(),
            "placeholder": "Informe o(a) "+this.getNames(n).toLowerCase(),
            "type": "number"
          })

      } else if(this.elementos[n].type=='string') {
        this.schema.push(
          {
            "label": this.getNames(n),
            "name": this.obterId(n).toString(),
            "placeholder": "Informe o(a) "+this.getNames(n).toLowerCase()
          })
      }
    },

    montarForm () {
      for(var it=0;it<this.elementos.length;it++) {
        this.inserirElemSchema(it)
      }
      this.schema.push({ "type": "submit" })
      console.log('Schema: ',this.schema)

    },
  },
  created () {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    EventBus.escutar('gameIDVersao',(obj) => {
      this.idJogo=obj.gameId
      this.idVersao=obj.versionId
      this.obterElem(this.idJogo)
    })

  },
}
</script>