<template>
  <div class="row">
     <div class="login row flex-column-reverse flex-lg-row m-0">
    <div class="bg-light col-xl-8">

        <h1 style="font-weight: 100; font-size: 2.2rem"><span style="font-weight: 700;">É o seu primeiro acesso?</span></h1>
        <h2 style="font-weight: 100; font-size: 1.8rem">
          <router-link
              tag="a"
              style="font-weight:bold"
              :to="{ name: 'cadastro'}"
              >Clique aqui</router-link> e faça o seu cadastro :)</h2>
    </div>
    <div class="conteudo d-flex align-items-center justify-content-center col-xl-4">
        <div class="w-100">
            <div class="col-12 text-center mb-5">
                <h3 class="titulo">Olá!</h3>
            </div>
            <p class="text-center"><b>Já tem um cadastro?</b><br/>Digite abaixo as suas credenciais</p>

            <div class="form-group row mt-5">
                <label for="email" class="col-md-12 col-form-label sr-only">Endereço de e-mail</label>

                <div class="col-md-12">
                    <input id="email" placeholder="Endereço de e-mail" v-model="form.email" type="email" class="form-control " name="email" value="" required autocomplete="email" autofocus>
                </div>
            </div>

            <div class="form-group row">
                <label for="password" class="col-md-12 col-form-label sr-only">Senha</label>

                <div class="col-md-12">
                    <input id="password" placeholder="Senha" v-model="form.password" type="password" class="form-control " name="password" required autocomplete="current-password">
                </div>
            </div>

            <div class="form-group row mb-0">
                <div class="col-md-12">
                    <button @click="logar" class="btn btn-outline-dark btn-block">
                        Acessar minha conta
                    </button>                    
                </div>
                
                
            </div>
            <div class="form-group row mb-0">
              <div class="d-flex justify-content-center align-items-center">
                <router-link
                  tag="a"
                  style="font-weight:bold"
                  :to="{ name: 'cadastro'}"
                  >Cadastre-se</router-link>
              </div>
            </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';
export default {
  components: {
  },
  data() {
    return {
      form: {
        email:'',
        name: '',
        password: '',
      },
      alertExclusao: true,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },
  methods: {
    logar(){
      backEnd.usuario.verificar(this.form, resp=> {
        if(resp.data != 1){
          VueCookie.set('authToken', resp.data, 1);
          this.$router.push({
          name: "home",
         });
        }else{
          this.alertExclusao = true;
          this.variantExclusao = "danger";
          this.mensagemExclusao = "Atenção! Login e senha não conferem!";
        }
        
      })
    },
  },
};
</script>