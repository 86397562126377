<template>
  <CardNoHeader>
    <template slot="corpo">
      <!-- formulario -->
      <h3>Novo jogo</h3>
      <br />
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Nome: <span class="text-danger">*</span></label>
          <b-form-input 
            type="text"
            class="form-control"
            placeholder="Nome do jogo"
            v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
            ></b-form-input>           
          
        </div>
        <div class="form-group">
          <label>Descrição: <span class="text-danger">*</span></label>
          <b-form-textarea
            class="form-control"
            placeholder="Descreva o jogo"
            v-model="dados.description"
            :state="(dados.description == null)? null: dados.description != ''"
          ></b-form-textarea>
        </div>
      </div>
      <div class="col-sm-6">
      <div class="form-group">
        <label>Link do jogo: <span class="text-danger">*</span></label>
        <b-form-input 
          type="text"
          class="form-control"
          placeholder="Insira a URL o jogo"
          v-model="dados.url"
        :state="(dados.url == null)? null: dados.url != ''"
            ></b-form-input>   
      </div>
      </div>
      </div>
      <div class="float-right">
        
        <button class="btn btn-secondary" @click="dados.name= null; dados.description= null; dados.url= null;">Limpar</button>
        <button class="btn btn-success" type="button" @click="cadastrar">
          Enviar
        </button>
      </div>
    </template>
  </CardNoHeader>
</template>

<script>
import axios from "axios";
import CardNoHeader from "@/components/layout/CardNoHeader";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    CardNoHeader,
  },

  data() {
    return {
      dados: {
        name: null,
        description: null,
        user_id: "",
        url: null,
      },
      
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },
  created () {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
  },
  methods: {
    cadastrar() {
      if(this.dados.name == '' || this.dados.description == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      axios
      .get(`${process.env.VUE_APP_API_URL}/users/list`)
      .then((resposta) => {
        console.log(resposta.data);
      })
      backEnd.usuario.obterId(VueCookie.get('authToken').toString(), id => {
          this.dados.user_id = id.data;
          backEnd.jogos.cadastrar(this.dados, VueCookie.get('authToken'), (resp) => {
          this.$router.push({
            name: "jogo-elementos",
            params: { id: resp.data.id, new: 'game' },
          });
        });
      })
      
    },
  },
};
</script>
