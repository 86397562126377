<template>
  <CardNoHeader>
    <template slot="corpo">
      <!-- formulario -->
      <h3>Editar jogo</h3>
      <br />
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
      <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Nome: <span class="text-danger">*</span></label>
          <b-form-input
            type="text"
            class="form-control"
            placeholder="Nome do jogo"
            v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
          ></b-form-input>
        </div>
        <div class="form-group">
          <label>Descrição: <span class="text-danger">*</span></label>
          <b-form-textarea
            class="form-control"
            placeholder="Descreva o jogo"
            v-model="dados.description"
          :state="(dados.description == null)? null: dados.description != ''"
          ></b-form-textarea>
        </div>
      </div>
      <div class="col-sm-6">
      <div class="form-group">
        <label>Link do jogo: <span class="text-danger">*</span></label>
        <b-form-input
          type="text"
          class="form-control"
          placeholder="Insira a URL o jogo"
          v-model="dados.url"
        :state="(dados.url == null)? null: dados.url != ''"
        ></b-form-input>
      </div>
      </div>
      </div>

      <div class="float-right">
        <router-link
              tag="button"
              class="btn btn-light"
              :to="{ name: 'jogos'}"
              exact
              >Voltar
            </router-link> 
        <button class="btn btn-secondary" @click="dados.name= null; dados.description= null; dados.url= null;">Limpar</button>
        <button class="btn btn-success" type="button" @click="enviar">
          Enviar
        </button>
      </div>
    </template>
  </CardNoHeader>
</template>

<script>
//import axios from "axios";
import CardNoHeader from "@/components/layout/CardNoHeader";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    CardNoHeader,
  },

  data() {
    return {
      id: this.$route.params.id,
      dados: {
        name: "",
        description: "",
      },
            
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  methods: {
    async enviar() {
      if(this.dados.name == '' || this.dados.description == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      await backEnd.jogos.editarJogo(this.id, this.dados, VueCookie.get('authToken'), (resp) => {
        this.$router.push({
          name: "jogos"
        });
        console.log(resp)
      });
    },
  },

  created () {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.jogos.obterJogo(this.id, VueCookie.get('authToken'), (resposta) => {
        this.dados = resposta.data;
        console.log(this.dados)
      });

  },
};
</script>
