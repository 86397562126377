<template>
  <div class="row">
    <div class="col-xl-6">      
      <Secao titulo="Versões" icone="lni lni-pallet">
        <CardNoHeader>
          <template slot="corpo">
            <h5>Para cadastrar uma <b>nova versão</b> clique 
      <router-link
      :to="{ name: 'stepperversoes' }"
      exact
      >aqui</router-link>.</h5>
            <Carregando :condicao="!listaVersions">
            <ul class="list-group" :key="at">              
              <li
                class="list-group-item"
                v-for="item in listaVersions"
                v-bind:key="item.game_id"
              >
                <!--<button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click="deletarVersao(item.id)"
                >
                  <span aria-hidden="true">×</span>
                </button>-->
                <p>
                  <strong>{{ item.name }}</strong
                  ><br />ID do jogo: {{ item.game_id }}
                </p>
              </li>
            </ul>

            </Carregando>

          </template>
        </CardNoHeader>
      </Secao>
    </div>

    <div class="col-xl-6">
      <Secao titulo="Jogos" icone="lni lni-game">
        <CardNoHeader>
          <template slot="corpo">
            <h5>Para cadastrar um criar um <b>novo jogo</b> clique  
            <router-link
            :to="{ name: 'jogos-cadastrar' }"
            exact
            >aqui</router-link>.
            <br/></h5>
            <Carregando :condicao="!listaJogos">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="item in listaJogos"
                v-bind:key="item.id"
              >
                <!--<button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click="deletarJogo(item.id)"
                >
                  <span aria-hidden="true">×</span>
                </button>-->
                <p>
                  <strong>{{ item.name }}</strong
                  ><br />ID: {{ item.id }}
                </p>
              </li>
            </ul>

            </Carregando> 
          </template>
        </CardNoHeader>
      </Secao>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Secao from "@/components/layout/Secao";
import Carregando from "@/components/layout/Carregando";
import CardNoHeader from "@/components/layout/CardNoHeader";
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Secao,
    CardNoHeader,
    Carregando,
  },

  data() {
    return {
      at: 0,
      listaJogos: null,
      listaVersions: null,
    };
  },
  methods: {
    deletarJogo(id) {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/games/delete/${id}`,{
          headers: {
            'token': VueCookie.get('authToken'),
          }
        })
        .then((response) => {
          console.log(response);
        });
    },
    deletarVersao(id) {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/versions/delete/${id}`,{
          headers: {
            'token': VueCookie.get('authToken'),
          }
        })
        .then((response) => {
          console.log(response);
        });
    },
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
  backEnd.jogos.obterLista(VueCookie.get('authToken'), (resposta) => {
        this.listaJogos = resposta.data;
      });
   

   backEnd.versoes.obterLista(VueCookie.get('authToken'),(resposta) => {
        this.listaVersions = resposta.data;
      });
   
  },
};
</script>
