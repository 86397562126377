<template>
    <b-navbar toggleable="lg" type="light">
        <!--<span class="toggleSidebar d-block d-sm-none" style="font-size: 1.5rem; margin-right: 1rem"><i
            class="lni lni-line-double"></i></span>-->

        <b-navbar-brand href="#">Adapter</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"><i class="lni lni-cog"></i></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto d-flex align-items-center">
                <Acessibilidade />
                <b-nav-item @click="sair()"><b>Sair</b></b-nav-item>
                    <b-button v-b-modal="'modal-excluir'" class="btn" variant="ligth">Excluir conta</b-button>
                    <b-modal :id="'modal-excluir'" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="excluirConta()">
                    <p class="my-4">Tem certeza que deseja excluir sua conta? <br/>
                        Isso apagara todos os jogos, versões e coleções que você criou.<br/>
                        Essa operação é irreversível!</p>
                    </b-modal> 
                
            </b-navbar-nav>
        </b-collapse>  
    </b-navbar>
</template>

<script>
import Acessibilidade from './Acessibilidade';
import backEnd from "@/utils/backEnd.js";
import VueCookie from 'vue-cookie';
export default {
   components: {
       Acessibilidade,
   },
   methods: {
       sair(){
            VueCookie.delete('authToken');
            this.$router.push({
                name: "login",
            });
       },
       excluirConta(){
           backEnd.usuario.obterId(VueCookie.get('authToken'), id => {
               backEnd.usuario.deletar(id.data, this.sair())
           });
       },
   }
};
</script>
