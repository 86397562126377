<template>
    <div class="d-flex">
        <b-nav-item-dropdown right>
            <template #button-content>
                <i class="fas fa-text-height"></i
                ><span class="sr-only">Acessibilidade</span>
            </template>
            <b-dropdown-header>Tamanho do texto</b-dropdown-header>
            <b-dropdown-item @click="aumentarFonte(-1)"
                >Diminuir</b-dropdown-item
            >
            <b-dropdown-item @click="reajustarFonte()">Padrão</b-dropdown-item>
            <b-dropdown-item @click="aumentarFonte(1)"
                >Aumentar</b-dropdown-item
            >
        </b-nav-item-dropdown>

        <!--b-nav-item class="tema" @click="alternarTema()"
            ><i :class="iconeContraste"></i>
            <span class="sr-only">Contraste</span></b-nav-item
        -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            fonte: 16,
            contraste: false,
        };
    },

    mounted() {
        const el = document.body;
        if (
            JSON.parse(localStorage.getItem("contraste")) &&
            !el.classList.contains("contraste")
        ) {
            el.classList.add("contraste");
            this.contraste = true;
            //this.$forceUpdate();
        }
        if (localStorage.getItem("fonte")) {
            this.alterarTamanhoFonte(localStorage.getItem("fonte"));
        }        
    },

    computed:{
        iconeContraste(){
            return this.contraste ? 'lni lni-sun' : 'lni lni-night';    
                    //this.$forceUpdate();
        },
    },

    methods: {
        

        alternarTema() {
            const el = document.body;

            if(!JSON.parse(localStorage.getItem("contraste"))){
                this.contraste = true;
               // this.$forceUpdate();
            }
            else {
                this.contraste = false;
                //this.$forceUpdate();
            }

            localStorage.setItem(
                "contraste",
                !JSON.parse(localStorage.getItem("contraste"))
            );
            el.classList.toggle("contraste");
            
        },

        aumentarFonte(tamanho) {
            this.fonte += tamanho;
            const el = document.body.parentNode;
            el.style.fontSize = this.fonte + "px";
            localStorage.setItem("fonte",this.fonte);  
        },

        reajustarFonte() {
            this.fonte = 16;
            const el = document.body.parentNode;
            el.style.fontSize = this.fonte + "px";
            localStorage.setItem("fonte",this.fonte);  
        },

        alterarTamanhoFonte(tamanho){
          const el = document.getElementsByTagName("html")[0];          
          el.style.fontSize = tamanho + 'px';
          localStorage.setItem("fonte",tamanho);  
        },
    }
};
</script>
