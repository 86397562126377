<template>
    <div class="secao">
        <div class="col-12">
            <h3 class="titulo"><i :class="icone"></i> {{ titulo }}</h3>
        </div>
        <div class="col-12">                    
            <slot></slot>
        </div>
    </div>   
</template>

<script>
export default {
    props: {
        icone: {
            type: String,
            required: true
        },
        titulo: {
            type: String,
            required: true
        }
    }
}
</script>