<template>
  <!-- Adicionar elementos ao jogo -->
  <div class="row">     
      <h3 id="titulo">Adicionar elementos no {{dadosJogo.name}}</h3>
      <b-alert fade v-if="$route.params.new == 'game'" show variant="success" dismissible>Novo jogo criado com sucesso!</b-alert>
      <b-alert fade v-if="$route.params.new == 'element'" show variant="success" dismissible>Novo elemento criado com sucesso!</b-alert>
      <b-alert fade v-if="$route.params.new == 'element_edit'" show variant="success" dismissible>Elemento editado com sucesso!</b-alert>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
      <div class="previous">
      <h5>Preencha o formulário abaixo para cadastrar novos elementos no jogo.</h5>
      <Formulario>
        <template #coluna-1>
            <div class="form-group">
              <label>Nome: <span class="text-danger">*</span></label>
              <b-form-input 
                type="text"
                class="form-control"
                placeholder="Nome do elemento"
                v-model="applicant.name"
              :state="(applicant.name == null)? null: applicant.name != ''"
            ></b-form-input>

              <label>Descrição: <span class="text-danger">*</span></label>
              <b-form-input 
                type="text"
                class="form-control"
                placeholder="Descrição do elemento"
                v-model="applicant.description"
              :state="(applicant.description == null)? null: applicant.description != ''"
            ></b-form-input>
            </div>
        </template>

        <template #coluna-2>
            <div class="form-group">
              <label>Tipo: <span class="text-danger">*</span></label>
              <select class="form-control" placeholder="Tipo" v-model="applicant.type" >
                <option value="" disabled >Selecione uma opção</option>
                <option v-for="(gameplayOpcao, indice) in gameplayOpcoes" :key="indice" :value="gameplayOpcao">{{ gameplayOpcao }}</option>
              </select>
            </div>

            <div class="form-group" v-if="applicant.type == 'Select'" >
              <label for="tags-remove-on-delete">Opções</label>
              <b-form-tags
                input-id="tags-remove-on-delete"
                :input-attrs="{
                  'aria-describedby': 'tags-remove-on-delete-help',
                }"
                v-model="dadosSelect"
                size="lg"
                separator=" "
                placeholder="Insira as opções do select"
                font-size="sm"
                remove-on-delete
              ></b-form-tags>
            </div>
            <div>
            <b-button
              class="btn btn-success"
              type="button"
              @click="enviar"
            >Adicionar</b-button>
            <router-link
              tag="button"
              class="btn btn-light"
              :to="{ name: 'jogos'}"
              exact
              >Voltar
            </router-link> 
          </div>
        </template>
      </Formulario>      
      </div>
      <br />

    <div>
      <Carregando :condicao="!listaElementosFiltrada">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>

      <Tabela :lista="listaElementosFiltrada" :campos="campos">
        <template v-slot:acoes="slotProps">
            <div>
              <button 
              :id="'edit'+slotProps.data.item.id"
              class="btn btn-sm btn-warning mx-1" @click="elemEditavel(slotProps.data.item.id)" ><i class="lni lni-pencil-alt"></i></button>
              <b-tooltip :target="'edit'+slotProps.data.item.id" triggers="hover" placement="left">
                Editar {{slotProps.data.item.name}} 
              </b-tooltip>
              <!-- Modal de exclusão -->
                <b-button 
                :id="'delete'+slotProps.data.item.id"
                v-b-modal="'modal-excluir-'+ slotProps.data.item.id" class="btn btn-sm btn-danger"><i class="lni lni-trash"></i></b-button>

                <b-modal :id="'modal-excluir-'+ slotProps.data.item.id" title="Atenção!" cancel-variant="ligth" ok-variant="danger" ok-title="Excluir" cancel-title="Cancelar" @ok="deletarElemento(slotProps.data.item.id)">
                  <p class="my-4">Tem certeza que deseja excluir {{slotProps.data.item.name}}? <br/>
                    Essa operação é irreversível!</p>
                </b-modal>
                <b-tooltip :target="'delete'+slotProps.data.item.id" triggers="hover" placement="left">
                  Excluir {{slotProps.data.item.name}} 
              </b-tooltip>
            </div>
        </template>
      </Tabela>
    </Carregando>
    </div>
    <div class="toast">
  <div class="toast-header">
    Toast Header
  </div>
  <div class="toast-body">
    Some text inside the toast body
  </div>
</div>
  </div>  
</template>

<script>
import axios from "axios";
import backEnd from "@/utils/backEnd.js";
import Formulario from "@/components/layout/Formulario.vue";
import EventBus from "@/utils/eventBus";
import Tabela from "@/components/layout/Tabela";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Formulario,
    Tabela,
    Carregando,
  },

  data () {
    return {
      dadosJogo: {
        name: "",
        description: "",
      },

      id_usuario: null,

      dadosSelect:null,

      gameplayOpcoes: ["String","Select","Boolean","Integer", "Collection"],

      applicant: 
        {
          game_id: this.$route.params.id,
          name: null,
          description: null,
          type: "",
        },
      

      //Variáveis da tabela de elementos
      listaElementosFiltrada: null,
      
      campos: [
          
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },

          {
            key: 'slug',
            label: 'Slug',
            sortable: true
          },

          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'created_at',
            label: 'Criado em',
            sortable: true
          },

           {
            key: 'updated_at',
            label: 'Atualizado em',
            sortable: true
          },        
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          }
        ],

        alertExclusao: false,
        variantExclusao : "",
        mensagemExclusao: "",

    };
      
  },
  methods: {
    elemEditavel (id_elemento){
      backEnd.elementos.obterElemento(id_elemento, VueCookie.get('authToken'), (elemento) => {
        if(elemento.data.totalVersoes == 0){
          this.$router.push({
          name: "elemento-editar",
          params: { id: id_elemento }
          });
        }else{
          this.mensagemExclusao = `Esse elemento não pode ser editado pois está sendo usado em ${elemento.data.totalVersoes} versão(ões) desse jogo.`;
          this.variantExclusao = "warning";
          this.alertExclusao = true;
          //alert(`Esse elemento não pode ser editado pois está sendo usado em ${elemento.data.totalVersoes} versões desse jogo.`)
        }
      });
    },

    enviar () {
      if(this.applicant.name == '' || this.applicant.description == '' || this.applicant.type == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      this.alertExclusao = false;
      this.applicant.type = this.applicant.type.toLowerCase();
      let elemento = [this.applicant];
      backEnd.usuario.obterId(VueCookie.get('authToken'), id_usuario => {
        elemento[0].user_id = id_usuario.data.toString();
        console.log(elemento[0])
        axios
        .post(`${process.env.VUE_APP_API_URL}/elements/create`,elemento,  {
           headers: {
              'token': VueCookie.get('authToken')
            }})
        .then((response) => {
          console.log(response.data)
          this.$route.params.new = "element";
          let inicio = response.data.indexOf("[game_id] => ") + 13;
          let fim = response.data.indexOf("[name]")-1;

          inicio = response.data.indexOf("[name] => ") + 10;
          fim = response.data.indexOf("[description] =>") -2;          
          let elementName = response.data.substring(inicio, fim).replace("\n", "").trim();

          inicio = response.data.indexOf("[type] => ") + 10;       
          let tipo = response.data.substring(inicio, inicio+6);

          let gameElementID = null;
          this.applicant.name = null;
          this.applicant.type = null;
          this.applicant.description = null;
          backEnd.elementos.listarPorJogo(this.$route.params.id, VueCookie.get('authToken'), (resp)=>{
            this.listaElementosFiltrada = resp.data;
            resp.data.forEach(elem => {
              if((elem.name == elementName) && (tipo == "select")){
                gameElementID = elem.id;
                if(gameElementID){
                  this.enviarSelect(gameElementID);
                  this.$route.params.new = 'element';
                }
              }else{
                this.$route.params.new = 'element';
              }
            });
          });        
        })
        .catch((err) => {
          console.log("Erro: ",err);
          alert("Falha ao definir elementos");
        });});
    
    },
    enviarSelect (elementId) {
      console.log(this.dadosSelect)
      this.dadosSelect.forEach((opcao) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/values/create`,{name: opcao, games_elements_id: elementId, value: opcao, user_id: this.id_usuario},  {
           headers: {
              'token': VueCookie.get('authToken')
            }})
          .then(resp=>{console.log(resp.data)})
          .catch((err) => {
            console.log("Erro: ",err);
          });
      });      
    },

    //Métodos da tabela
    deletarElemento (id_elem) {
      backEnd.elementos.obterElemento(id_elem, VueCookie.get('authToken'), (resp) => {
        if(resp.data.totalVersoes !== 0){
          this.mensagemExclusao = `Esse elemento não pode ser excluído pois está sendo usados em ${resp.data.totalVersoes} versão(ões) desse jogo.`
          this.variantExclusao = "danger";
          this.alertExclusao = true;
        }else{
          //Esse elemento não está sendo usado em nenhuma versão (não possui versionParameters cadastrados)
          backEnd.valores_elemento.listarPorElemento(id_elem, VueCookie.get('authToken'), (listaElemValues) => {
            if(listaElemValues.data.length == 0){
              backEnd.elementos.deletar(id_elem, VueCookie.get('authToken'), () => {
                backEnd.elementos.listarPorJogo(this.$route.params.id, VueCookie.get('authToken'), (resp)=>{
                  this.listaElementosFiltrada = resp.data;
                });
                this.mensagemExclusao = "Elemento excluído com sucesso!";
                this.variantExclusao = "success";
                this.alertExclusao = true;
              });
            }else{
              listaElemValues.data.forEach((elem_value, index) => {
                backEnd.valores_elemento.deletar(elem_value.id, VueCookie.get('authToken'), () => {
                  if(index == listaElemValues.data.length -1){//excluiu o último element value
                    backEnd.elementos.deletar(id_elem, VueCookie.get('authToken'), () => {
                      backEnd.elementos.listarPorJogo(this.$route.params.id, VueCookie.get('authToken'), (resp)=>{
                        this.listaElementosFiltrada = resp.data;
                      });
                      this.mensagemExclusao = "Elemento excluído com sucesso!";
                      this.variantExclusao = "success";
                      this.alertExclusao = true;                   
                    });
                  }
                });
              });
            }            
          });          
        }
      });
    }
  },
  created () {
    backEnd.usuario.obterId(VueCookie.get('authToken'),id =>{
      this.id_usuario = id.data;
    });
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    EventBus.escutar("gameID",() => {
      this.applicant[0].game_id=this.$route.params.id;
      this.gameID=this.$route.params.id;
    });

    backEnd.jogos.obterJogo(this.$route.params.id, VueCookie.get('authToken'), (resposta) => {
        if(resposta){
          this.dadosJogo = resposta.data
        }
      });
    backEnd.elementos.listarPorJogo(this.$route.params.id, VueCookie.get('authToken'), (resp)=>{
      this.listaElementosFiltrada = resp.data;
    });
  },

};
</script>
