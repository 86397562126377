<template>

  <Carregando :condicao="dados == null">
      <template slot="spinner">
        <b-skeleton-table
          :rows="15"
          :columns="7"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </template>

      <!-- formulario -->
      <h3>Editando {{nomeColecao}}:</h3>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert>  
      <br />
      <div class="row">
        <div class="col-sm-5">
          <div class="form-group">
            <label>Nome: <span class="text-danger">*</span></label>
            <b-form-input
              type="text"
              class="form-control"
              :placeholder="nomeColecao"
              v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
          ></b-form-input>  
          </div>
        </div>

        <div class="col-sm-5">
          <div class="form-group">
            <label>Descrição: <span class="text-danger">*</span></label>
            <b-form-textarea
              class="form-control"
              :placeholder="descColecao"
              v-model="dados.description"
            :state="(dados.description == null)? null: dados.description != ''"
          ></b-form-textarea>
          </div>
      </div>
      </div>

      <div class="float-right">
        <router-link
          tag="button"
          class="btn btn-light"
          :to="{ name: tipoColecao.toLowerCase()}"
          exact
          >Voltar</router-link> 
        <button class="btn btn-success" type="button" @click="editar()">
          Enviar
        </button>
      </div>
  </Carregando>
</template>

<script>
//import axios from "axios";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
  },

  data() {
    return {
      dados: null,
      nomeColecao: null,
      
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecao.obterColecao(this.$route.params.id, VueCookie.get('authToken'), (resposta) => {
      this.dados = resposta.data;
      this.nomeColecao = resposta.data.name;
      this.descColecao = resposta.data.description;
    });
  },

  methods: {
    editar() {
      if(this.dados.name == '' || this.dados.description == ''){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      backEnd.colecao.editarColecao(this.$route.params.id, this.dados, VueCookie.get('authToken'),(resp)=> {
        console.log(resp);
        let destino = '';
        if(this.dados.type == 'questions'){
          destino = "perguntas";
        }
        if(this.dados.type == 'images'){
          destino = "imagens";  
        }
        if(this.dados.type == 'sounds'){
          destino = "sons";  
        }
        this.$router.push({name: destino});  
      });
    },
  },
  computed: {
    tipoColecao (){
      if(this.$route.params.type == 'questions'){
        return "Perguntas"
      }else if(this.$route.params.type == 'images'){
        return "Imagens"
      }else{
        return ""
      }
    },
  },
};
</script>
