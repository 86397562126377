import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './router'
import VueFormulate from '@braid/vue-formulate';
import 'vuetify/dist/vuetify.min.css'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
import Vuetify from 'vuetify'



Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueFormulate);
Vue.use(Vuetify)

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
