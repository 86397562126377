<template>
  <Carregando :condicao="listaColecoes == null || dados == null || collectionsLocal == null">    
      <!-- formulario -->
      <h3>Editando {{nomeLocal}}:</h3>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
      <br />
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Nome do Som: <span class="text-danger">*</span></label>
            <b-form-input
              type="text"
              class="form-control"
              v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
            ></b-form-input>      
          </div>
          <label>Coleções que contêm o som: <span class="text-danger">*</span></label>
          <b-form-checkbox name="colecoes" v-model="dados.collections" v-for="colecao in listaColecoes" :key="colecao.id" :value="colecao.id">{{colecao.name}}</b-form-checkbox>
        </div>
        
        <div class="col-sm-6">
          <label for="som-antigo">Arquivo do Som:</label>
          <b-row class="content-md-center">
            <audio controls autoplay>
              <source :src="obterSom(dados.id)" type="audio/ogg">
              <source :src="obterSom(dados.id)" type="audio/mpeg">
              <source :src="obterSom(dados.id)" type="audio/wav">
              Seu navegador não suporta a reprodução de aúdios.
            </audio> 
          </b-row>   <br/>      
          <label for="som-novo">Substituir Arquivo: <span class="text-danger">*</span></label>
          <b-form-file
                id="som-novo"
                v-model="dados.file"
                placeholder="Selecione um som..."
                drop-placeholder="Solte um som aqui..."
                accept=".wav"
                :state="(dados.file == null)? null: true"
          ></b-form-file>
        </div></div>
      
      <div class="float-right">
        <router-link
          tag="button"
          class="btn btn-light"
          :to="{ name: 'sons'}"
          exact
          >Voltar</router-link> 
        <button class="btn btn-success m-2" type="button" @click="cadastrar">
          Enviar
        </button>
      </div>
  </Carregando>
</template>
<script>
//import axios from "axios";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
  },

  data() {
    return {
      dados: null,      
      listaColecoes: null,
      collectionsLocal: null,
      nomeLocal: '',
      
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecao.listarPorTipo("sounds", VueCookie.get('authToken'),(resposta) => {
      this.listaColecoes = resposta.data;
    });
    
    backEnd.som.obterSom(this.$route.params.id, VueCookie.get('authToken'), resp => {
      this.dados = resp.data;
      this.nomeLocal = resp.data.name;
      console.log("dados: ",this.dados)
      this.dados.collections = [];
      backEnd.colecaoSom.listarPorSom(this.$route.params.id, VueCookie.get('authToken'),(resposta) => {
        this.collectionsLocal = resposta.data;
        this.collectionsLocal.forEach(item => {
          this.dados.collections.push(item.collection_id);
        });
      });
      
    });
  },

  methods: {
    obterSom(){
      return `${process.env.VUE_APP_API_URL}`+this.dados.path;
    },
    fileChange(e){
      console.log(e.target.files[0]);
      this.dados.file = e.target.files[0];
    },
    cadastrar() {
      if(this.dados.name == '' || this.dados.collections.length == 0 ){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      console.log(this.dados);
      if(this.dados.file !== undefined){
        backEnd.som.deletarArquivo(this.dados.id, VueCookie.get('authToken'), resp => {
          console.log(resp);
          let dados = new FormData();
          dados.append('name', this.dados.name);
          dados.append('file', this.dados.file);
          console.log("data: ",dados)
          backEnd.som.editarSomArquivo(this.dados.id, dados, VueCookie.get('authToken'), som => {
            console.log("Som: ",som);
            if(this.dados.collections.length !== 0){
              backEnd.colecaoSom.listarPorSom(this.dados.id, VueCookie.get('authToken'), resp => {
                resp.data.forEach((item,index) => {
                  backEnd.colecaoSom.deletar(item.id, VueCookie.get('authToken'), data => console.log(data));
                  if(index == resp.data.length -1){
                    console.log("colec",this.dados.collections);
                    this.dados.collections.forEach(id_colecao => {
                      console.log('id colecao:' ,id_colecao)
                      console.log("Som: ",this.dados.id);
                      let colecaoSom= {
                        collection_id: id_colecao, 
                        sound_id: this.dados.id,
                        user_id: this.dados.user_id
                      }
                      backEnd.colecaoSom.cadastrar(colecaoSom, VueCookie.get('authToken'), resposta => {
                        console.log(resposta);
                        backEnd.colecaoSom.listar( VueCookie.get('authToken'), lista => console.log("Lista collection-sound por som:", lista.data))  
                      });
                    });
                  }
                });
              });
            }
            this.$router.push({name: 'sons'});
          });
        })
      }else{
        console.log("arquivo nao modificado")
        backEnd.som.editarSom(this.dados.id, this.dados, VueCookie.get('authToken'), som => {
          console.log(som);
          backEnd.colecaoSom.listarPorSom(this.dados.id, VueCookie.get('authToken'), resp => {
            resp.data.forEach((item,index) => {
              backEnd.colecaoSom.deletar(item.id, VueCookie.get('authToken'), data => console.log(data));
              if(index == resp.data.length -1){
                console.log("colec",this.dados.collections)
                if(this.dados.collections.length == 0){
                  this.dados.collections = [];
                  this.collectionsLocal.forEach(item => {
                    this.dados.collections.push(item.collection_id);
                  })
                }
                this.dados.collections.forEach(id_colecao => {
                  backEnd.colecaoSom.cadastrar({collection_id: id_colecao, sound_id: som.data.id, user_id: this.dados.user_id}, VueCookie.get('authToken'), resposta => {
                    console.log(resposta);
                    backEnd.colecaoSom.listar( VueCookie.get('authToken'), lista => console.log("Lista collection-sound por som:", lista.data))  
                  });
                });
              }
            });
          });
          this.$router.push({name: 'sons'});
        });
      }      
    },
  },

  computed: {
  },
};
</script>
