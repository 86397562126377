import Vue from 'vue'
import VueRouter from 'vue-router'

import Listar from '@/views/Jogo/Listar';
import ListarVersoes from '@/views/Versoes/ListarVersoes';
import EditarVersao from '@/views/Versoes/EditarVersao';
import EditarElemento from '@/views/Jogo/EditarElemento';
import CadastrarJogo from '@/views/Jogo/CadastrarJogo';
import StepperJogo from '@/views/Jogo/StepperJogo';
import EditarJogo from '@/views/Jogo/EditarJogo';
import StepperVersoes from '@/views/Versoes/StepperVersoes';
import Home from '@/views/Home';
import Login from '@/views/Login';
import Cadastro from '@/views/Cadastro';
import Imagens from '@/views/Colecoes/Imagens';
import Sons from '@/views/Colecoes/Sons';
import Perguntas from '@/views/Colecoes/Perguntas';
import CadastrarColecao from '@/views/Colecoes/CadastrarColecao';
import EditarColecao from '@/views/Colecoes/EditarColecao';
import EditarPergunta from '@/views/Colecoes/EditarPergunta';
import EditarImagem from '@/views/Colecoes/EditarImagem';
import EditarSom from '@/views/Colecoes/EditarSom';
import CadastrarPergunta from '@/views/Colecoes/CadastrarPergunta';
import ListarPerguntas from '@/views/Colecoes/ListarPerguntas';
import ListarImagens from '@/views/Colecoes/ListarImagens';
import ListarSons from '@/views/Colecoes/ListarSons';
import CadastrarImagem from '@/views/Colecoes/CadastrarImagem';
import CadastrarSom from '@/views/Colecoes/CadastrarSom';
import DefinirElementos from '@/views/Jogo/DefinirElementos';
import CriarVersao from '@/views/Versoes/CriarVersao';
import Logado from '@/Logado';

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'login',
    component: Login,
  },{
    path: '',
    name: 'cadastro',
    component: Cadastro,
  },{
    path: '/painel',
    name: 'painel',
    component: Logado,
    children: [
      {
        path: 'jogos-cadastrar',
        name: 'jogos-cadastrar',
        component: CadastrarJogo,  
          },
      {
        path: '',
        name: 'home',
        component: Home,
      }, 

      {
        path: 'jogos/:id/elementos/:new',
        name: 'jogo-elementos',
        component: DefinirElementos,  
      }, 

      {
        path: 'jogos/:id/versoes/:new',
        name: 'jogo-versoes',
        component: ListarVersoes,  
      }, 

      {
        path: 'jogos/:id/editar',
        name: 'jogo-editar',
        component: EditarJogo,  
      }, 

      {
        path: 'versao/:id/editar',
        name: 'versao-editar',
        component: EditarVersao,  
      },

      {
        path: 'elemento/:id/editar',
        name: 'elemento-editar',
        component: EditarElemento,  
      },

      {
        path: 'jogos',
        name: 'jogos',
        component: Listar,  
      
      },  
      
      {
        path: 'stepperjogo',
        name: 'stepperjogo',
        component: StepperJogo,
      },  
      {
        path: 'criarversao',
        name: 'criarversao',
        component: CriarVersao,
      },    
      {
        path: 'stepperversoes',
        name: 'stepperversoes',
        component: StepperVersoes,
      },   
      {
        path: 'definirelementos',
        name: 'definirelementos',
        component: DefinirElementos,
      }, 
      {
        path: 'imagens',
        name: 'imagens',
        component: Imagens,
      }, 
      {
        path: 'perguntas',
        name: 'perguntas',
        component: Perguntas,
      },
      {
        path: 'editar-pergunta/:id',
        name: 'editar-pergunta',
        component: EditarPergunta,
      }, 
      {
        path: 'colecao/:type',
        name: 'cadastrar-colecao',
        component: CadastrarColecao,
      },
      {
        path: 'pergunta/',
        name: 'cadastrar-pergunta',
        component: CadastrarPergunta,
      }, 
      {
        path: 'editar-colecao/:type/:id',
        name: 'editar-colecao',
        component: EditarColecao,
      },
      {
        path: 'listar-perguntas/:id_colecao',
        name: 'listar-perguntas',
        component: ListarPerguntas,
      },
      {
        path: 'listar-imagens/:id_colecao',
        name: 'listar-imagens',
        component: ListarImagens,
      },
      {
        path: 'imagem/',
        name: 'cadastrar-imagem',
        component: CadastrarImagem,
      }, 
      {
        path: 'editar-imagem/:id',
        name: 'editar-imagem',
        component: EditarImagem,
      },
      {
        path: 'sons/',
        name: 'sons',
        component: Sons,
      }, 
      {
        path: 'listar-sons/:id_colecao',
        name: 'listar-sons',
        component: ListarSons,
      },
      {
        path: 'som/',
        name: 'cadastrar-som',
        component: CadastrarSom,
      }, 
      {
        path: 'editar-som/:id',
        name: 'editar-som',
        component: EditarSom,
      },
    ],
  },
  
]

const router = new VueRouter({
  routes, // short for `routes: routes`
  //mode: 'history',
  //mode: 'history',
  //base: '/adapt/',
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router