<template>
  <Carregando :condicao="listaColecoes == null || dados == null || collectionsLocal == null">    
      <!-- formulario -->
      <h3>Editando {{nomeLocal}}:</h3>
      <b-alert fade :show="alertExclusao" :variant="variantExclusao" dismissible
        @dismissed="alertExclusao=false;"
      >{{mensagemExclusao}}</b-alert> 
      <br />
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Nome da Imagem: <span class="text-danger">*</span></label>
            <b-form-input
              type="text"
              class="form-control"
              v-model="dados.name"
            :state="(dados.name == null)? null: dados.name != ''"
            ></b-form-input>        
          </div>
          <label>Coleções que contêm a imagem: <span class="text-danger">*</span></label>
          <b-form-checkbox name="colecoes" v-model="dados.collections" v-for="colecao in listaColecoes" :key="colecao.id" :value="colecao.id">{{colecao.name}}</b-form-checkbox>
        </div>
        
        <div class="col-sm-6">
          <label for="imagem-antiga">Arquivo da Imagem:</label>
         <v-img id="imagem-antiga" 
          max-height="500"
          max-width="500" 
          contain
          :src="obterImagem()"/>
          
          <label for="imagem-nova">Substituir Arquivo: <span class="text-danger">*</span></label>
          <b-form-file
                id="imagem-nova"
                v-model="dados.file"
                placeholder="Selecione uma imagem..."
                drop-placeholder="Solte uma imagem aqui..."
                accept=".jpg, .jpeg"
                :state="(dados.file == null)? null: true"
          ></b-form-file>
        </div></div>
      
      <div class="float-right">
        <router-link
          tag="button"
          class="btn btn-light"
          :to="{ name: 'imagens'}"
          exact
          >Voltar</router-link> 
        <button class="btn btn-success m-2" type="button" @click="cadastrar">
          Enviar
        </button>
      </div>
  </Carregando>
</template>
<script>
//import axios from "axios";
/* import EventBus from "@/utils/eventBus" */
import backEnd from "@/utils/backEnd.js";
import Carregando from "@/components/layout/Carregando";
import VueCookie from 'vue-cookie';

export default {
  components: {
    Carregando,
  },

  data() {
    return {
      dados: null,      
      listaColecoes: null,
      collectionsLocal: null,
      nomeLocal: '',
      
      alertExclusao: false,
      variantExclusao : "",
      mensagemExclusao: "",
    };
  },

  created() {
    if(VueCookie.get('authToken') == null){
      this.$router.push({
        name: "login",
      });
    }
    backEnd.colecao.listarPorTipo("images", VueCookie.get('authToken'),(resposta) => {
      this.listaColecoes = resposta.data;
    });
    
    backEnd.imagem.obterImagem(this.$route.params.id, VueCookie.get('authToken'), resp => {
      this.dados = resp.data;
      this.nomeLocal = resp.data.name;
      console.log("dados: ",this.dados)
      this.dados.collections = [];
      backEnd.colecaoImagem.listarPorImagem(this.$route.params.id, VueCookie.get('authToken'),(resposta) => {
        this.collectionsLocal = resposta.data;
        this.collectionsLocal.forEach(item => {
          this.dados.collections.push(item.collection_id);
        });
      });
      
    });
  },

  methods: {
    obterImagem(){
      return `${process.env.VUE_APP_API_URL}`+this.dados.path;
    },
    /*fileChange(e){
      console.log(e.target.files[0]);
      this.dados.file = e.target.files[0];
    },*/
    cadastrar() {
      if(this.dados.name == '' || this.dados.collections.length == 0 ){
        this.alertExclusao = true;
        this.variantExclusao = "danger";
        this.mensagemExclusao = "Atenção! É necessário preencher todos os campos.";
        return null;
      }
      console.log(this.dados);
      if(this.dados.file !== undefined){
        backEnd.imagem.deletarArquivo(this.dados.id, VueCookie.get('authToken'), resp => {
          console.log(resp);
          let dados = new FormData();
          dados.append('name', this.dados.name);
          dados.append('file', this.dados.file);
          console.log("data: ",dados)
          backEnd.imagem.editarImagemArquivo(this.dados.id, dados, VueCookie.get('authToken'), imagem => {
            console.log("Imagem: ",imagem);
            if(this.dados.collections.length !== 0){
              backEnd.colecaoImagem.listarPorImagem(this.dados.id, VueCookie.get('authToken'), resp => {
                resp.data.forEach((item,index) => {
                  backEnd.colecaoImagem.deletar(item.id, VueCookie.get('authToken'), data => console.log(data));
                  if(index == resp.data.length -1){
                    console.log("colec",this.dados.collections);
                    this.dados.collections.forEach(id_colecao => {
                      console.log('id colecao:' ,id_colecao)
                      console.log("Imagem: ",this.dados.id);
                      let colecaoImagem = {
                        collection_id: id_colecao, 
                        image_id: this.dados.id,
                        user_id: this.dados.user_id
                      }
                      backEnd.colecaoImagem.cadastrar(colecaoImagem, VueCookie.get('authToken'), resposta => {
                        console.log(resposta);
                        backEnd.colecaoImagem.listar( VueCookie.get('authToken'), lista => console.log("Lista collection-imagem por imagem:", lista.data))  
                      });
                    });
                  }
                });
              });
            }
            this.$router.push({name: 'imagens'});
          });
        })
      }else{
        console.log("arquivo nao modificado")
        backEnd.imagem.editarImagem(this.dados.id, this.dados, VueCookie.get('authToken'), imagem => {
          console.log(imagem);
          backEnd.colecaoImagem.listarPorImagem(this.dados.id, VueCookie.get('authToken'), resp => {
            resp.data.forEach((item,index) => {
              backEnd.colecaoImagem.deletar(item.id, VueCookie.get('authToken'), data => console.log(data));
              if(index == resp.data.length -1){
                console.log("colec",this.dados.collections)
                if(this.dados.collections.length == 0){
                  this.dados.collections = [];
                  this.collectionsLocal.forEach(item => {
                    this.dados.collections.push(item.collection_id);
                  })
                }
                this.dados.collections.forEach(id_colecao => {
                  backEnd.colecaoImagem.cadastrar({collection_id: id_colecao, image_id: imagem.data.id, user_id: this.dados.user_id}, VueCookie.get('authToken'), resposta => {
                    console.log(resposta);
                    backEnd.colecaoImagem.listar( VueCookie.get('authToken'), lista => console.log("Lista collection-imagem por imagem:", lista.data))  
                  });
                });
              }
            });
          });
          this.$router.push({name: 'imagens'});
        });
      }      
    },
  },

  computed: {
  },
};
</script>
